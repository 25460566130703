import Raven from 'raven-js';
import CONFIG from '../../config/config.yaml';

const init = () => {
  // Sentry Raven
  if (process.env.NODE_ENV === 'production') {
    Raven.config(CONFIG.raven);
    Raven.install();
    // https://github.com/getsentry/raven-js/issues/424
    window.addEventListener('unhandledrejection', evt => {
      Raven.captureException(evt.reason);
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { init };
