import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SummonField from './SummonField';

import {
  requiredValidator,
  emailValidator,
  mobileValidator,
  confirmValidator,
} from '../validators';

const Text = props => {
  const { name, disabled, email, textarea, confirmValue, field } = props;
  const {
    label,
    labelStyle = {},
    placeholder,
    required,
    validators = '',
    size = 2,
    maxSize = 4,
    maxChar,
    confirms,
    ...input
  } = field;

  const validatorsArray = [];
  _.forEach(_.compact(_.castArray(validators)), value => {
    switch (value) {
      case 'mobile':
        validatorsArray.push(mobileValidator);
        break;
      default:
        break;
    }
  });
  let isRequired = required;
  if (!_.isNil(confirms)) {
    validatorsArray.push(confirmValidator(confirmValue));
    isRequired = true; // Confirmation is always a required field
  }
  if (isRequired) validatorsArray.push(requiredValidator);
  if (email) validatorsArray.push(emailValidator);

  return (
    <SummonField
      key={`summonField-${name}`}
      reduxFormField={{
        name,
        validate: [...validatorsArray],
        normalize: value =>
          maxChar
            ? _.truncate(value, { length: maxChar, omission: '' })
            : value,
      }}
      component="text"
      componentProps={{
        label: label && (
          <span style={labelStyle}>
            {label}
            {isRequired && (
              <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
            )}
          </span>
        ),
        placeholder,
        disabled,
        fullWidth: true,
        multiline: textarea,
        rows: textarea ? size : 1,
        rowsMax: textarea ? maxSize : 1,
        ...input,
      }}
    />
  );
};

Text.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validators: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    size: PropTypes.number,
    maxSize: PropTypes.number,
    maxChar: PropTypes.number,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    confirms: PropTypes.bool,
  }).isRequired,
  confirmValue: PropTypes.string,
  email: PropTypes.bool,
  textarea: PropTypes.bool,
};

Text.prototype.defaultProps = {
  disabled: false,
  confirmValue: null,
  email: false,
  textarea: false,
};

export default Text;
