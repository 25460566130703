import _ from 'lodash';
import jsonata from 'jsonata/jsonata-es5'; // because of UglifyJsPlugin

const isConditionFulfilled = (condition, values, { roles, metrics }) => {
  let fulfilled = false;

  if (condition.field) {
    // Field equals Value
    const fieldValue = values[condition.field];
    // Stringified JSON data might has the wrong type, hence ==
    // eslint-disable-next-line eqeqeq
    fulfilled = fieldValue == condition.value;
  } else if (condition.filledField) {
    // Field have been filled
    const fieldValue = values[condition.filledField];
    fulfilled = !_.isNil(fieldValue) && !_.isEmpty(fieldValue);
  } else if (condition.role) {
    // User have a role
    fulfilled = _.includes(roles, condition.role);
  } else if (condition.query) {
    // Complex query
    const query = jsonata(condition.query);
    fulfilled = !!query.evaluate({ ...values, metrics });
  }

  return fulfilled;
};

export default isConditionFulfilled;
