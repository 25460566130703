import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Markdown from '../../lib/SimpleContent/Markdown';

const Contact = () => (
  <div className="tab">
    <Card>
      <CardContent>
        <Markdown mdKey="dataprivacy" />
      </CardContent>
    </Card>
  </div>
);

export default Contact;
