import React from 'react';
import PropTypes from 'prop-types';

import Html from '../../SimpleContent/Html';

const HtmlContent = props => {
  const { name, field } = props;
  const { content, ...input } = field;
  return <Html {...input} key={name} html={content} />;
};

HtmlContent.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default HtmlContent;
