import _ from 'lodash';
import moment from 'moment';

import {
  SET_IS_MOBILE,
  SET_IS_LOADING,
  TOGGLE_LEFT_DRAWER,
  SET_LANDING_ACTIVE,
  SET_LANDING_ACTIVE_TAB,
  SET_CALENDAR,
  SET_SELECTED_EVENTS,
  REMOVE_SELECTED_EVENTS,
  SET_METRICS,
  DISABLE_SWIPE,
  ENABLE_SWIPE,
} from './actions';

const DEFAULT_STATE = {
  isMobile: false,
  isLoading: true,
  leftDrawerOpened: false,
  landingActive: false,
  landingActiveTab: 0,
  initialValues: {},
  calendar: {},
  eventList: [],
  metrics: [],
};

const setIsMobileReducer = (state, { isMobile }) => ({ ...state, isMobile });

const setIsLoadingReducer = (state, { isLoading }) => ({ ...state, isLoading });

const toggleLeftDrawerReducer = (state, { forceValue }) => {
  const leftDrawerOpened =
    typeof forceValue === 'undefined' ? !state.leftDrawerOpened : forceValue;
  return { ...state, leftDrawerOpened };
};

const setLandingActiveReducer = (state, { landingActive }) => ({
  ...state,
  landingActive,
});

const setMetrics = (state, { metrics }) => ({ ...state, metrics });

const setLandingActiveTabReducer = (state, { activeTab: landingActiveTab }) => {
  if (landingActiveTab < 0) {
    return { ...state, landingActiveTab: DEFAULT_STATE.landingActiveTab };
  }
  return { ...state, landingActiveTab };
};

const getEventListFromCalendar = calendar =>
  !_.isEmpty(calendar)
    ? _(_.cloneDeep(calendar)) // cloneDeep or the calendar values would also be affected
        .map((event, eventName) => {
          event.value = eventName; // Identifier / select's option's value
          event.selected = false;
          event.unavailable = false;
          return event;
        })
        .sortBy(event => moment(event.start).valueOf())
        .values()
        .value()
    : [];

const setCalendarReducer = (state, { calendar = {} }) => {
  const eventList = getEventListFromCalendar(calendar);
  return { ...state, calendar, eventList };
};

const setSelectedEventsReducer = (
  state,
  { addedSelectedEvents, removedSelectedEvents },
) => {
  const listOfSelectedEvents = [];
  // Update selected events
  let eventList = _.map(_.cloneDeep(state.eventList), event => {
    if (_.includes(addedSelectedEvents, event.value)) event.selected = true;
    if (_.includes(removedSelectedEvents, event.value)) event.selected = false;
    if (event.selected) listOfSelectedEvents.push(event);
    return event;
  });

  // Set the availability
  eventList = _.map(eventList, event => {
    if (event.selected) {
      event.unavailable = false;
      return event;
    }
    // Compare the event with all the selected ones
    // Search for overlapping selected events
    const overlappingSelectedEvents = _.find(
      listOfSelectedEvents,
      selectedEvent => {
        const startsBeforeEnd = moment(event.start).isBefore(
          selectedEvent.stop,
        );
        const endsAfterStart = moment(event.stop).isAfter(selectedEvent.start);
        // If the event both:
        //  1. starts before a selected event ends
        //  2. ends after a selected event starts
        // => those 2 events overlap
        return startsBeforeEnd && endsAfterStart;
      },
    );
    event.unavailable = !_.isUndefined(overlappingSelectedEvents);
    return event;
  });

  return { ...state, eventList };
};

const removeSelectedEventsReducer = state => ({
  ...state,
  eventList: getEventListFromCalendar(state.calendar),
});

const disableSwipeReducer = state => ({ ...state, swipingDisabled: true });
const enableSwipeReducer = state => ({ ...state, swipingDisabled: false });

const rootReducer = function rootReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_IS_MOBILE:
      return setIsMobileReducer(state, action);
    case SET_IS_LOADING:
      return setIsLoadingReducer(state, action);
    case TOGGLE_LEFT_DRAWER:
      return toggleLeftDrawerReducer(state, action);
    case SET_LANDING_ACTIVE:
      return setLandingActiveReducer(state, action);
    case SET_LANDING_ACTIVE_TAB:
      return setLandingActiveTabReducer(state, action);
    case SET_CALENDAR:
      return setCalendarReducer(state, action);
    case SET_SELECTED_EVENTS:
      return setSelectedEventsReducer(state, action);
    case REMOVE_SELECTED_EVENTS:
      return removeSelectedEventsReducer(state, action);
    case SET_METRICS:
      return setMetrics(state, action);
    case DISABLE_SWIPE:
      return disableSwipeReducer(state, action);
    case ENABLE_SWIPE:
      return enableSwipeReducer(state, action);
    default:
      return state;
  }
};

export default rootReducer;
