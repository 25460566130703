import { createSelector } from 'reselect';

export const getMetrics = state => state.root.metrics || [];

export const getMetricsByRole = createSelector(
  getMetrics,
  metrics =>
    // index by role
    metrics.reduce(
      (prev, { role, ...rest }) => ({
        ...prev,
        [role]: rest,
      }),
      {},
    ),
);

export const getQuotaReached = createSelector(
  getMetrics,
  metrics =>
    metrics.reduce((result, metric) => {
      result[metric.role] = metric.value >= metric.quota;
      return result;
    }, {}),
);
