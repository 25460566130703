import _ from 'lodash';
import Raven from 'raven-js';
import moment from 'moment-timezone';
import AWS from 'aws-sdk';
import crypto from 'crypto';
import ReactGA from 'react-ga';

import { loginActions, ResaCredentials } from 'phileog-login-client';

import {
  initialize as initializeReduxForm,
  SubmissionError,
  destroy as destroyReduxForm,
  getFormInitialValues,
  change,
} from 'redux-form';

import { invokeLambda } from '../../lib/lambda';

import CONFIG from '../../config/config.yaml';
import GRAPHQL from '../../config/graphql.yaml';
import CALENDAR from '../../config/calendar.json';
import FORMS from '../../config/forms.yaml';
import MAILINGS from '../../config/mailings.yaml';

import {
  setProfile,
  setProfileUid,
  resetProfile,
} from '../profile/actionCreators';
import {
  initializeSummonForm,
  setFinished as setFinishedSummonForm,
  onSubmit as onSubmitSummonForm,
  resetForm as resetSummonForm,
} from '../summonForm/actionCreators';

import {
  SET_IS_MOBILE,
  SET_IS_LOADING,
  TOGGLE_LEFT_DRAWER,
  SET_LANDING_ACTIVE,
  SET_LANDING_ACTIVE_TAB,
  SET_CALENDAR,
  SET_SELECTED_EVENTS,
  REMOVE_SELECTED_EVENTS,
  SET_METRICS,
  DISABLE_SWIPE,
  ENABLE_SWIPE,
} from './actions';

import { PayloadError } from '../../lib/Utils';

var initializationDeferred: { resolve?: Function; reject?: Function } = {};
var initialization = new Promise<void>((resolve, reject) => {
  initializationDeferred = { resolve, reject };
});

export const setIsMobile: (isMobile: boolean) => Action = isMobile => ({
  type: SET_IS_MOBILE,
  isMobile,
});

export const setIsLoading: (isLoading: boolean) => Action = isLoading => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const toggleLeftDrawer: (
  forceValue: boolean,
) => Action = forceValue => ({
  type: TOGGLE_LEFT_DRAWER,
  forceValue,
});

export const setLandingActive: (landingActive: boolean) => Action = (
  landingActive = true,
) => ({
  type: SET_LANDING_ACTIVE,
  landingActive,
});

export const setLandingActiveTab: (activeTab) => Action = activeTab => ({
  type: SET_LANDING_ACTIVE_TAB,
  activeTab,
});

export const setCalendar: (calendar) => Action = calendar => ({
  type: SET_CALENDAR,
  calendar,
});

export const setSelectedEvents: (
  addedSelectedEvents: any,
  removedSelectedEvents?: any,
) => Action = (addedSelectedEvents, removedSelectedEvents) => ({
  type: SET_SELECTED_EVENTS,
  addedSelectedEvents,
  removedSelectedEvents,
});

const removeSelectedEvents: () => Action = () => ({
  type: REMOVE_SELECTED_EVENTS,
});

export const setMetrics: (metrics) => Action = metrics => ({
  type: SET_METRICS,
  metrics,
});

const mapQueryToValues: (rawValues: Resa.Data) => Resa.Data = rawValues => {
  const mappings: {
    [k: string]: {
      store: string;
      key: string;
      value: string;
      simple?: boolean;
    };
  } = FORMS.calendarMappings;
  const values = _.cloneDeep(rawValues);
  const mappingValueStoresToDelete: string[] = [];
  // Add values to query result, extract from it
  // Get the list of stores from which split the values
  _.forEach(mappings, (mappingValues, mappingField) => {
    if (!_.isEmpty(values[mappingValues.store])) {
      const entries = values[mappingValues.store];
      if (typeof entries !== 'object') return;
      // For each stores extract the values to their keys
      _.forEach(entries, entry => {
        // Select only the calendar entries that match the mapping key
        // ex: mappingValues.key   = folder
        //     mappingValues.value = "a")
        if (CALENDAR[entry][mappingValues.key] === mappingValues.value) {
          if (mappingValues.simple) {
            values[mappingField] = entry;
          } else {
            if (_.isUndefined(values[mappingField])) values[mappingField] = [];
            (values[mappingField] as any[]).push(entry);
          }
        }
      });
    }
    mappingValueStoresToDelete.push(mappingValues.store);
  });
  _.forEach(mappingValueStoresToDelete, mappingValueStore => {
    // Remove query's store from values
    delete values[mappingValueStore];
  });

  // filter special types
  const { twin: twinTypes = [], date: dateTypes = [] } = GRAPHQL.schema;
  twinTypes.forEach((field: string) => {
    const v = values['field'];
    if (typeof v === 'object') {
      values[field] = v.uid;
      values[`${field}Action`] = null;
    }
  });
  dateTypes.forEach(field => {
    const date = moment(values[field]).toDate();
    values[field] = values[field] ? date : null;
  });

  // START AXA HOOK

  // TODO: Globalize (https://bitbucket.org/phileog/resa/issues/209)
  if (values.arrDate) {
    // Get the date, shift to right timezone and then remove the timezone
    const arrDate = moment(values.arrDate)
      .tz('Europe/Paris')
      .format('YYYY-MM-DDTHH:mm:ss');
    // Recreate the date (user's timezone is used but no shift operated)
    values.transferArrTime = moment(arrDate);
    values.transferArrDate = moment(arrDate).format('YYYY-MM-DD');
  } else {
    values.transferArrTime = null;
    values.transferArrDate = null;
  }
  if (values.depDate) {
    // Get the date, shift to right timezone and then remove the timezone
    const depDate = moment(values.depDate)
      .tz('Europe/Paris')
      .format('YYYY-MM-DDTHH:mm:ss');
    // Recreate the date (user's timezone is used but no shift operated)
    values.transferDepTime = moment(depDate);
    values.transferDepDate = moment(depDate).format('YYYY-MM-DD');
  } else {
    values.transferDepTime = null;
    values.transferDepDate = null;
  }

  // END AXA HOOK

  return values;
};

/**
 * Used on submit to map form values to data structure in database
 * @param  {object} rawValues Form values
 * @return {object}           Query structured values
 * @author Sylvain Pont
 */
const mapValuesToQuery: (
  rawValues: Resa.Data,
  visitedSteps: number[],
  finished: boolean,
  firstTimeFinishForm: boolean,
  profile?: State['profile'],
) => Resa.Data = (
  rawValues,
  visitedSteps,
  finished,
  firstTimeFinishForm,
  profile = { firstName: '', lastName: '' },
) => {
  const mappings = FORMS.calendarMappings;
  const values = _.cloneDeep<Resa.Data>(rawValues);

  // Fill the values[store] from calendar mappings
  _.forEach(mappings, (fieldMapping: { store: string }, fieldName) => {
    if (values[fieldName]) {
      values[fieldMapping.store] = _.concat(
        values[fieldMapping.store] || [],
        values[fieldName], // may be array or string (works with multiple and single selects)
      );
      delete values[fieldName]; // don't pass unknown variables
    }
  });

  // Set the visited steps as a string
  if (!_.isEmpty(visitedSteps)) {
    values.steps = visitedSteps.join(',');
    // Consider the form finished (server side) as long as the user finished it once
    if (finished || !firstTimeFinishForm) {
      values.steps += ',!';
    }
  }

  // Set the finished state as "presence = 1"
  // If presence field is NOT ALREADY filled and [OR]
  // - If not in stepper (no visitedSteps)
  // - If in stepper and finished
  // 2022 remove undefined condition
  if (/* _.isUndefined(values.presence) && */(_.isEmpty(visitedSteps) || finished)) {
    values.presence = 1;
  }

  // filter special types
  const {
    twin: twinTypes = [],
    date: dateTypes = [],
    room: roomTypes = [],
  } = GRAPHQL.schema;
  twinTypes.forEach(field => {
    const twinActionField = `${field}Action`;
    const roomField = roomTypes.length && roomTypes[0];
    // https://github.com/facebook/flow/issues/2982
    let action = ['invite', 'accept', 'cancel'].find(
      v => v === values[twinActionField],
    );
    // If room is set and presence === 0, we are cancelling
    if (
      roomField &&
      profile[roomField] &&
      profile[roomField].id &&
      values.presence === 0
    ) {
      action = 'cancel';
    }
    values[field] = {
      uid: values[field],
      action,
    };
    delete values[twinActionField];
  });
  dateTypes.forEach(field => {
    const date = moment(values[field]).format();
    values[field] = values[field] ? date : null;
  });

  // GDPR special, only set after login
  if (profile.gdpr) {
    values.gdpr = moment(profile.gdpr).format();
  }

  // START AXA HOOK

  const {
    transferArrDate,
    transferArrTime,
    transferDepDate,
    transferDepTime,
  } = values;

  if (transferArrDate) {
    const arrMomentDate = moment(values.transferArrDate);

    const arrMomentObj = {
      year: arrMomentDate.year(),
      month: arrMomentDate.month(),
      day: arrMomentDate.date(),
      hour: transferArrTime ? transferArrTime.hours() : '00',
      minute: transferArrTime ? transferArrTime.minutes() : '00',
    };

    const arrMoment = moment.tz(arrMomentObj, 'Europe/Paris');
    values.arrDate = arrMoment.format();
  } else {
    values.arrDate = null;
  }

  if (transferDepDate) {
    const depMomentDate = moment(transferDepDate);

    const depMomentObj = {
      year: depMomentDate.year(),
      month: depMomentDate.month(),
      day: depMomentDate.date(),
      hour: transferDepTime ? transferDepTime.hours() : '00',
      minute: transferDepTime ? transferDepTime.minutes() : '00',
    };

    const depMoment = moment.tz(depMomentObj, 'Europe/Paris');
    values.depDate = depMoment.format();
  } else {
    values.depDate = null;
  }

  delete values.transferArrTime;
  delete values.transferArrDate;
  delete values.transferDepTime;
  delete values.transferDepDate;

  // END AXA HOOK

  return values;
};

export const initializeFromUid: (
  forcedUid?: string,
) => ThunkAction = forcedUid => (dispatch, getState, { aws }) => {
  const {
    login: { profile: loginProfile },
  } = getState();

  // TODO - move to some selector, could come from querystring in the future (see LoginMagicLink)
  const { template, entry } = FORMS.registerDefaults || {};
  const data = template ? FORMS.templateMapping[template][entry].value : {};
  // merge profile with registerDefaults for new users (when login with google)
  const profile = { ...data, ...loginProfile };

  const payload = {
    body: {
      query: GRAPHQL.query,
      variables: { uid: forcedUid || profile.uid },
      resaKey: CONFIG.resaKey,
    },
  };

  return invokeLambda(payload, aws)()
    .then(response => {
      const { resa: r = {} } = response.body.data;
      if (!CONFIG.autoregister && (!r || !r.uid)) {
        const err = new PayloadError('Unknown uid', payload);
        throw err;
      }

      const resa: Resa.Data = r === null ? {} : r; // non matched row is null
      if (!resa) {
        const err = new PayloadError('Data retrieve error: no data', payload);
        throw err;
      }

      const retrievedData: Resa.Data = { ...resa };
      const touched: string[] = [];
      // override empty values with login profile (including uid and picture)
      // only if uids match !
      if (!retrievedData.uid || retrievedData.uid === profile.uid) {
        Object.keys(profile).forEach(k => {
          if (
            ((GRAPHQL.schema.string && GRAPHQL.schema.string.includes(k)) ||
              k === 'uid') && // uid is ID, FIXME should be in schema ?
            !retrievedData[k] &&
            profile[k]
          ) {
            touched.push(k);
          } else if (retrievedData[k] === null && profile[k] !== undefined) {
            touched.push(k);
          }
        });
      }

      // Initialize profile
      dispatch(setProfile(retrievedData));

      const initialValues: Resa.Data = mapQueryToValues(
        _.pickBy<Resa.Data>(retrievedData, value => value !== null) as Resa.Data,
      );
      // Initialize form values
      // TODO: SUPPORT FOR MULTIPLE FORMS !
      dispatch(
        //                  formName           data       keepDirty options
        initializeReduxForm('inscriptionForm', initialValues, false, {}),
      );
      // touched
      touched.forEach(t => {
        dispatch(change('inscriptionForm', t, profile[t]));
      });

      if (typeof retrievedData.eventList === 'object') {
        // Initialize selected events
        dispatch(setSelectedEvents(retrievedData.eventList));
      }

      // Initialize summonForm (state)
      dispatch(initializeSummonForm(retrievedData));

      initializationDeferred.resolve?.call(null);
    })
    .catch(error => {
      console.error('Lambda query invoke failed', error);
      dispatch(setProfileUid('')); // resetProfile ?
      if (error.code === 'CredentialsError') {
        // token is probably expired, should auto refresh ?
        console.warn('Lambda Credentials Error');
      }
      const err = new PayloadError('Lambda query invoke failed', payload);
      Raven.captureException(err, {
        level: 'warning',
        tags: { resaKey: CONFIG.resaKey },
      });

      initializationDeferred.reject?.call(null);
    })
    .then(() => {
      dispatch(setIsLoading(false));
    });
};

export const onLogin: (token: any) => ThunkAction = token => (
  dispatch,
  getState,
  { aws },
) => {
  if (token) {
    aws.init(token.creds, dispatch);
    const creds: ResaCredentials = AWS.config.credentials;
    (creds as ResaCredentials).refresh(() => {
      const { identityId } = creds;
      if (identityId) {
        const md5 = crypto
          .createHash('md5')
          .update(identityId)
          .digest('hex');
        ReactGA.set({ userId: md5 });
        ReactGA.event({
          category: 'Login',
          action: 'login',
        });
      }
    });
    return dispatch(initializeFromUid());
  }
  dispatch(setIsLoading(false));
  return token;
};

export const onLogout: () => ThunkAction = () => dispatch => {
  dispatch(setIsLoading(false));
  dispatch(resetProfile());
  dispatch(resetSummonForm()); // FIXME - does it work ?
  ReactGA.event({
    category: 'Login',
    action: 'logout',
  });
  ReactGA.set({ userId: '' });
};

export const loginLogin: (loginHint: string, params: string) => ThunkAction = (
  loginHint,
  params,
) => dispatch => {
  dispatch(setIsLoading(true));
  dispatch(setProfile({ gdpr: new Date() })); // set GDPR on login
  dispatch(loginActions.login(loginHint, params));
};

export const loginGetToken: () => void = () => {
  console.warn('Deprecated, now managed directly by login-client');
};

export const loginLogout: () => ThunkAction = () => dispatch =>
  dispatch(loginActions.logout());

export const submitData = (formName, payload, rawValues) => (
  dispatch,
  getState,
  { aws },
) =>
  invokeLambda(payload, aws)()
    .then(response => {
      const {
        body: { errors },
      } = response;
      if (errors) {
        console.error('Data submit error, give up', payload, errors);
        throw new SubmissionError({
          _error: 'An error occured',
        });
      }
    })
    .catch(error => {
      console.error('Lambda submit invoke failed', error);
      const err = new PayloadError('Lambda submit invoke failed', payload);
      Raven.captureException(err, {
        level: 'warning',
        tags: { resaKey: CONFIG.resaKey },
      });
      // FIXME - This should not silently fail
      if (error.code === 'CredentialsError') {
        // token is probably expired, FIXME auto-refresh
        console.warn('Lambda Credentials Error');
      }
      throw error; // throw again
    })
    .then(() => {
      dispatch(onSubmitSummonForm());
      // Set new submit state (not submitted) + new pristine state
      // defer because redux-form sets submitSucceeded when this promise ends
      _.defer(() => {
        if (rawValues)
          dispatch(initializeReduxForm(formName, rawValues, false, {}));
      });
    });

export const handleSubmit: (rawValues: Resa.Data) => ThunkAction = rawValues => (
  dispatch,
  getState,
) => {
  if (rawValues) {
    const state = getState();
    const {
      summonForm: {
        firstTimeSubmitForm,
        firstTimeFinishForm,
        visitedSteps,
        finished,
      },
      profile,
      login: { profile: loginProfile },
    } = state;
    const initial: any = getFormInitialValues('inscriptionForm')(state);

    const isFinished = finished || _.isEmpty(visitedSteps);
    if (isFinished && !finished) dispatch(setFinishedSummonForm());

    // Format values to match query
    const values = mapValuesToQuery(
      rawValues,
      visitedSteps,
      isFinished,
      firstTimeFinishForm,
      {
        ...loginProfile,
        ...profile,
      },
    );

    const { mutation: query } = GRAPHQL;
    const { resaKey } = CONFIG;
    const resaThen: Resa.Then = {
      mailing: [],
      sms: [],
    };

    // Send mail with programme if user wants it
    if (isFinished && (CONFIG.sendMailing || values.sendMailing)) {
      resaThen.mailing = [
        {
          resaKey,
          mailing: 'confirmation',
          dest: [{ uid: values.uid }],
          data: MAILINGS.confirmation.data || {},
        },
      ];
    }

    // Send SMS to coordinator if (OR)
    // - first time first step done
    // - first time last step done
    if (
      CONFIG.sendSms &&
      (firstTimeSubmitForm || (isFinished && firstTimeFinishForm))
    ) {
      resaThen.sms = [
        {
          resaKey,
          template: 'notification',
          dest: [{ uid: values.uid }],
        },
      ];
    }

    const payload = {
      body: {
        query,
        variables: values,
        resaKey,
        then: resaThen,
      },
    };
    return dispatch(submitData('inscriptionForm', payload, rawValues)).then(
      res => {
        if (
          CONFIG.yammer &&
          CONFIG.yammer.sendActivity &&
          values.presence &&
          !initial.presence
        )
          return dispatch((d, gS, { loginRelay }) =>
            loginRelay.yamrActivity(values.uid, {
              action: CONFIG.yammer?.action || 'resa:register',
              ogUrl: `https://jasmin.resa-event.com/${resaKey}/index.html?login_hint=yamr#/`,
            }),
          );
        if (isFinished && values.presence !== initial.presence) {
          ReactGA.event({
            category: 'Submit',
            action: 'presence',
            value: values.presence ? 10 : 0,
          });
        } else {
          ReactGA.event({
            category: 'Submit',
            action: 'step',
          });
        }
        return res;
      },
    );
  }
  throw new SubmissionError({
    _error: 'Invalid values!',
  });
};

export const reset: () => ThunkAction = () => dispatch => {
  dispatch(resetSummonForm());
  dispatch(removeSelectedEvents());
  dispatch(destroyReduxForm('inscriptionForm'));
  if (CONFIG.anonymous) {
    dispatch(
      initializeReduxForm('inscriptionForm', { uid: 'anonymous' }, false, {}),
    );
  }
};

export const executeQuery: (
  opts1: {
    query: string;
    values?: { [k: string]: any };
    queryName: string;
    queryType?: 'autocompleteUsers' | 'twinCandidates';
  },
  opts2?: { logged?: boolean },
) => ThunkAction = ({ query, values, queryName, queryType }, opts2 = {}) => (
  dispatch,
  getState,
  { aws },
) => {
  const { logged = false } = opts2;
  let promise: Promise<any>;
  if (logged) {
    promise = initialization;
  } else {
    promise = aws.ready();
  }

  return promise.then(() => {
    const state = getState();
    const userId = _.get(state, 'profile.uid', '');
    const variables = {};
    _.forEach(values, ({ name, value, type }) => {
      let variableValue = value;
      if (type === 'variable') {
        if (variableValue === 'userId') {
          variableValue = userId;
        }
      }
      variables[name] = variableValue;
    });
    const payload = {
      body: {
        query,
        variables,
        resaKey: CONFIG.resaKey,
      },
    };
    return invokeLambda(payload, aws)().then(res => {
      let queryResult = _.get(res, `body.data.${queryName}`);
      if (queryResult) {
        if (queryType === 'autocompleteUsers') {
          queryResult = queryResult.filter(user => user.value !== userId);
          const twin: { [k: string]: string } = _.get(
            state,
            'profile.twin',
            null,
          );
          if (_.isObject(twin)) {
            const { firstName, lastName, mail, uid } = twin;
            if (!queryResult.find(user => user.value === uid)) {
              queryResult.push({
                label: `${firstName} ${lastName} (${mail})`,
                value: uid,
              });
            }
          }
        } else if (queryType === 'twinCandidates') {
          _.forEach(queryResult, user => {
            const { firstName, lastName, mail, uid } = user;
            user.label = `${firstName} ${lastName} (${mail})`;
            user.value = uid;
          });
        }
        res.body.data[queryName] = queryResult;
      }
      return res;
    });
  });
};

export const getMetrics: () => ThunkAction = () => dispatch => {
  const query = `query Stats {
                     metrics {
                       role  # Metric identifier, as in calendar.json's "quota"
                       label # For the admin page
                       value # Current value
                       quota # Maximum value
                     }
                   }`;
  return dispatch(executeQuery({ query, queryName: 'metrics' }))
    .then(response => {
      const {
        body: { errors },
      } = response;
      if (errors) {
        console.error('Data submit error, give up', query, errors);
        throw new SubmissionError({
          _error: 'An error occured',
        });
      }
      return dispatch(setMetrics(response.body.data.metrics));
    })
    .catch(error => {
      console.error(error);
    });
};

export const uploadFile: (
  opts1: { file: File; meta: {} },
  progressCallback: () => void,
  abortCallbackSaver: (callback: () => void) => void,
  options?: { logged: boolean },
) => ThunkAction = (
  { file, meta },
  progressCallback,
  abortCallbackSaver,
  options,
) => (dispatch, getState, { aws }) => {
  const { logged = true } = options || {};
  let promise: Promise<void>;
  if (logged) {
    promise = initialization;
  } else {
    promise = aws.ready();
  }

  return promise.then(() => {
    const upload = aws.uploadS3(file, meta);
    upload.on('httpUploadProgress', progressCallback);
    abortCallbackSaver(upload.abort.bind(upload));
    return upload.promise();
  });
};

export const disableSwipe = () => ({
  type: DISABLE_SWIPE,
});

export const enableSwipe = () => ({
  type: ENABLE_SWIPE,
});
