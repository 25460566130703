import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { toggleLeftDrawer } from '../../redux/root/actionCreators';

import Sticky from '../../lib/Sticky';
import Avatar from './Avatar';
import Header from './Header';

import CUSTOMIZATION from '../../config/customization.yaml';

class AppHeader extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleToggleDrawer = _.bind(this.handleToggleDrawer, this);
  }

  handleToggleDrawer() {
    const { dispatch } = this.props;
    dispatch(toggleLeftDrawer());
  }

  render() {
    const {
      header: { sticky = {} },
      menu: { button: menuButton = {} },
    } = CUSTOMIZATION;

    return (
      <div>
        <Sticky
          sticky={sticky}
          menuButton={menuButton}
          toggleMenu={this.handleToggleDrawer}
          AvatarComponent={Avatar}
        />
        <Header />
      </div>
    );
  }
}
export default connect()(AppHeader);
