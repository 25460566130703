import { createSelector } from 'reselect';
import _ from 'lodash';

import { isSiteEnded, isSiteClosed } from './siteDates';
import { getRoles } from './profile';

import mappingComponents from '../components/mappingComponents';

import CUSTOMIZATION from '../config/customization.yaml';

const { menu } = CUSTOMIZATION;

const {
  Welcome,
  Information,
  MainForm,
  InscriptionsClosed,
  Invite,
  Admin,
  Video,
  Overview,
} = mappingComponents;

export const getLandingActiveTab = state => state.root.landingActiveTab;

export const getMenuItems = createSelector(
  isSiteEnded,
  getRoles,
  (siteIsEnded, userRoles) => {
    const itemsAfter = !_.isNil(menu.itemsAfter) ? menu.itemsAfter : menu.items;
    let items = siteIsEnded ? itemsAfter : menu.items;
    items = items.filter(
      item => _.isUndefined(item.role) || _.includes(userRoles, item.role),
    );
    return items;
  },
);

export const getTabItems = createSelector(
  isSiteEnded,
  isSiteClosed,
  getMenuItems,
  (siteIsEnded, siteIsClosed, menuItems) =>
    menuItems
      .filter(item => item.type === 'tab')
      .map(item => {
        // `item` can now have a `Component` and an optional `componentProps` property
        switch (item.href) {
          case '/welcome':
            item.Component = Welcome;
            break;
          case '/inscription':
          case '/registration':
            if (siteIsEnded) {
              item.Component = InscriptionsClosed;
            } else if (siteIsClosed) {
              item.Component = InscriptionsClosed;
            } else {
              item.Component = MainForm;
            }
            break;
          case '/infos':
          case '/seminar':
            item.Component = Information;
            break;
          case '/video':
            item.Component = Video;
            break;
          case '/invite':
          case '/invitation':
            item.Component = Invite;
            break;
          case '/admin':
            item.Component = Admin;
            break;
          case '/overview':
            item.Component = Overview;
            break;
          default:
        }
        return item;
      }),
);

export const getDefaultTab = createSelector(
  getMenuItems,
  menuItems => menuItems.filter(m => m.type === 'tab')[0],
);
