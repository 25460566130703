import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { FormattedMessage } from 'react-intl';

const TwinToggle = props => {
  const { name, onChange, actionValue, required } = props;

  const yesNo = [
    {
      value: 'yes',
      label: <FormattedMessage id="toggle.yes" defaultMessage="Yes" />,
    },
    {
      value: 'no',
      label: <FormattedMessage id="toggle.no" defaultMessage="No" />,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginBottom: '1em',
      }}
    >
      <span style={{ flexGrow: '1' }}>
        <FormattedMessage
          id="twins.chose.question"
          defaultMessage="Would you like to select your room-mate now ?"
        />
        {required && (
          <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
        )}
      </span>
      <RadioGroup
        name="twin-toggle-action"
        onChange={(event, value) => onChange(value)}
        value={actionValue}
        style={{ flexDirection: 'row' }}
      >
        {yesNo.map(option => (
          <FormControlLabel
            key={`${name}-${option.value}`}
            value={option.value}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

TwinToggle.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  actionValue: PropTypes.string,
  required: PropTypes.bool,
};

TwinToggle.defaultProps = {
  actionValue: null,
  required: false,
};

export default TwinToggle;
