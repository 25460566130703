import { createSelector } from 'reselect';
import moment from 'moment';

import CONFIG from '../config/config.yaml';

const { dates } = CONFIG;

export const getBeginDate = () => moment(dates.begin || '2000-01-01');

export const getCloseDate = () => moment(dates.close);

export const getEndDate = () => moment(dates.end);

export const isSiteNotYetOpen = createSelector(
  getBeginDate,
  beginDay => beginDay.isAfter(),
);

export const isSiteOpen = createSelector(
  getBeginDate,
  getCloseDate,
  (beginDay, closeDay) => beginDay.isBefore() && closeDay.isAfter(),
);

export const isSiteClosed = createSelector(
  getCloseDate,
  getEndDate,
  (closeDay, endDay) => closeDay.isBefore() && endDay.isAfter(),
);

export const isSiteEnded = createSelector(
  getEndDate,
  endDay => endDay.isBefore(),
);
