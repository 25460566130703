import React from 'react';
import PropTypes from 'prop-types';

import SummonField from './SummonField';

import { toString, toInt } from '../normalizers';
import { requiredValidator } from '../validators';

const Radio = props => {
  const { name, disabled, field } = props;
  const { label, required, isInt, labelStyle = {}, ...input } = field;
  const validatorsArray = [];
  if (required) validatorsArray.push(requiredValidator);
  return (
    <SummonField
      key={`summonField-${name}`}
      reduxFormField={{
        name,
        validate: [...validatorsArray],
        parse: isInt ? toInt : toString,
        format: v => `${v}`,
      }}
      component="radio"
      componentProps={{
        label: label && (
          <span style={labelStyle}>
            {label}
            {required && (
              <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
            )}
          </span>
        ),
        disabled,
        isInt,
        ...input,
      }}
    />
  );
};

Radio.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.shape().isRequired,
};

export default Radio;
