import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const MuiCard = ({ children }) => (
  <Card>
    <CardContent>{children}</CardContent>
  </Card>
);

MuiCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiCard;
