import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VideogPlayer from 'videog-player';
import Carousel, { generateCarouselImages } from '../../lib/Carousel';

import photos from '../../config/carousel.yaml';

const photoUrlPrefix =
  'https://d2bz2v6wegwutd.cloudfront.net/static/pala/1803-cps-relive/photos';

const playerTheme = {
  palette: {
    background: 'rgb(0, 0, 0)',
    // primary: 'rgb(203, 0, 68)',
    controls: {
      background: 'rgb(245, 245, 245)',
      contrastText: 'rgb(62, 61, 64)',
    },
    progress: {
      primary: 'rgb(203, 0, 68)',
      background: 'rgb(224, 225, 221)',
      buffer: 'rgb(100, 99, 102)',
      over: 'rgb(100, 99, 102)',
    },
    tooltip: {
      background: 'rgb(100, 99, 102)',
      contrastText: 'rgb(250, 250, 250)',
    },
  },
};

class VideoPage extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.photos = {
      cafe: generateCarouselImages(photos.cafe, photoUrlPrefix),
    };
  }

  render() {
    const { isActive } = this.props;
    return (
      <div>
        <Card>
          <CardContent>
            <VideogPlayer
              id="7b4a9f25c0173bedeb2cd4715540945f3572733b"
              theme={playerTheme}
              isActive={isActive}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <h1>Photos</h1>
            <Carousel
              images={this.photos.cafe}
              maxHeight="300px"
              sideOverlap="15%"
              isActive={isActive}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default VideoPage;
