export const RESET = 'summonForm/RESET';
export const SET_FIRST_TIME_SUBMIT_FORM =
  'summonForm/SET_FIRST_TIME_SUBMIT_FORM';
export const SET_FIRST_TIME_FINISH_FORM =
  'summonForm/SET_FIRST_TIME_FINISH_FORM';
export const SET_VISITED_STEPS = 'summonForm/SET_VISITED_STEPS';
export const SET_FINISHED = 'summonForm/SET_FINISHED';
export const SET_SHOW_FORM = 'summonForm/SET_SHOW_FORM';
export const RESET_STEPS = 'summonForm/RESET_STEPS';
export const BLOCK_FORM = 'summonForm/BLOCK_FORM';
export const RELEASE_FORM = 'summonForm/RELEASE_FORM';
