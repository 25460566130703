import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import FORMS from '../config/forms.yaml';

import { getMetricsByRole } from './metrics';
import { getRoles } from './profile';
import isConditionFulfilled from '../lib/SummonForm/conditions';

const getSteps = formName => () => {
  if (formName !== 'inscriptionForm')
    throw new Error(`FIXME - steps not supported for ${formName}`);
  return FORMS.inscription.steps || [];
};

export const getHiddenSteps = formName =>
  createSelector(
    getFormValues(formName),
    getRoles,
    getSteps(formName),
    getMetricsByRole,
    (formValues = {}, roles, steps, metrics) => {
      const disabledSteps = [];
      steps.forEach((step, stepIndex) => {
        const { condition } = step;
        const disabled =
          condition &&
          !isConditionFulfilled(condition, formValues, { roles, metrics });
        if (disabled) disabledSteps.push(stepIndex);
      });
      return disabledSteps;
    },
  );

export const getVisibleStepFields = formName =>
  createSelector(
    getFormValues(formName),
    getRoles,
    getSteps(formName),
    getMetricsByRole,
    (formValues = {}, roles, steps, metrics) => {
      const disabledStepFields = [];
      steps.forEach(step => {
        disabledStepFields.push(
          step.fields
            .filter(
              ({ condition }) =>
                !condition ||
                isConditionFulfilled(condition, formValues, {
                  roles,
                  metrics,
                }),
            )
            .map(f => f.name),
        );
      });
      return disabledStepFields;
    },
  );

export default getHiddenSteps;
