// eslint-disable-next-line
import '!style-loader!../../tools/config-css-loader.js!yaml-loader?asJSON=true!../config/customization.yaml';
// move to webpack alias ?

import information from './information.md';
import welcome from './welcome.md';
import contact from './contact.md';
import overview from './overview.md';
import dataprivacy from './dataprivacy.md';
import faq from './faq.md';
import registrationNo from './registration_no.md';
import registrationNotSure from './registration_not_sure.md';

export default {
  information,
  welcome,
  overview,
  contact,
  dataprivacy,
  faq,
  registrationNo,
  registrationNotSure,
};
