import { defineMessages } from 'react-intl';

const application = defineMessages({
  localStorageNeeded: {
    id: 'app.localstorage.needed',
    defaultMessage:
      'This application needs local storage feature to be enabled on your browser.',
  },
  updateBrowser: {
    id: 'app.update.brower',
    defaultMessage: 'Please update your browser or leave private mode.',
  },
  logout: {
    id: 'app.logout',
    defaultMessage: 'Logout',
  },
  sessionExpired: {
    id: 'app.session.expired',
    defaultMessage: 'Your session has expired, this page will reload',
  },
  errorOccured: {
    id: 'app.session.error',
    defaultMessage: 'An error has occured, this page will reload',
  },
  siteNotOpened: {
    id: 'app.inscription.notOpened',
    defaultMessage: 'Site not opened yet.',
  },
  inscriptionClosed: {
    id: 'app.inscription.closed',
    defaultMessage: 'Registration is now closed.',
  },
  siteEnded: {
    id: 'app.inscription.ended',
    defaultMessage: 'Site terminated.',
  },
  theTeam: {
    id: 'app.inscription.theteam',
    defaultMessage: 'The organisation team.',
  },
});

const summonForm = defineMessages({
  dontForget: {
    id: 'formstate.dontforget',
    defaultMessage: "Don't forget to submit your modifications",
  },
  thankSubmitted: {
    id: 'formstate.thanksubmitted',
    defaultMessage: 'Thank you. Your choices have been successfully submitted.',
  },
  submit: {
    id: 'formstate.submit',
    defaultMessage: 'Submit',
  },
  event: {
    id: 'formstate.event',
    defaultMessage: 'event',
  },
  events: {
    id: 'formstate.events',
    defaultMessage: 'events',
  },
  back: {
    id: 'formstate.back',
    defaultMessage: 'Back',
  },
  saveBack: {
    id: 'formstate.saveBack',
    defaultMessage: 'Save and Back',
  },
  confirmRegistration: {
    id: 'formstate.confirmRegistration',
    defaultMessage: 'Confirm your registration',
  },
  next: {
    id: 'formstate.next',
    defaultMessage: 'Next',
  },
  saveNext: {
    id: 'formstate.saveNext',
    defaultMessage: 'Save and Next',
  },
  step: {
    id: 'formstate.step',
    defaultMessage: 'Step',
  },
});

export { application, summonForm };
