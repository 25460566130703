import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import FoodIcon from '@material-ui/icons/Restaurant';

import Markdown from '../../lib/SimpleContent/Markdown';

import Html from '../../lib/SimpleContent/Html';
import FoodIcon from '../../lib/icons/Dining';

import CALENDAR from '../../config/calendar.json';

/** @typedef {import('../../types/axa').AXAEvent} AXAEvent */

const PRIVATE_IM = false;

const style = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '6px',
  padding: '5px',
};
const roundStyle = (color) => ({
  backgroundColor: color,
  width: 18,
  height: 18,
  borderRadius: '50%',
});
const lineStyle = (color) => ({
  backgroundColor: color,
  width: 6,
  height: '100%',
  margin: '0 6px',
  fontSize: 32,
  color,
});

/** @type {React.FC<{ event: AXAEvent, participate: boolean, overlap: boolean }>} */
const Event = (props) => {
  const { event, participate, overlap/* , index, size */} = props;
  const { start, stop, html_overview: html, class: icon, category } = event;
  let time;
  if (moment.tz(stop, 'Europe/Paris').format('H') === '0') {
    time = `from ${moment.tz(start, 'Europe/Paris').format('h.mm a')}`;
  } else {
    time = `${moment.tz(start, 'Europe/Paris').format('h.mm a')} - ${moment
      .tz(stop, 'Europe/Paris')
      .format('h.mm a')}`;
  }
  const icons = {
    'rond-gris': <div style={roundStyle('rgb(45,58,65)')} />, 
    'rond-violet': <div style={roundStyle('rgb(127,99,215)')} />,
    'rond-vert': <div style={roundStyle('rgb(39,172,186)')} />,
    'rond-orange': <div style={lineStyle('rgb(222,97,6)')} />,
    'trait-vert': (
      <div style={lineStyle('rgb(39,172,186)')}>
        {category === 'meal' ? (
          <FoodIcon
            fontSize="inherit"
            style={{ position: 'absolute', right: 14, top: 14, opacity: 0.6 }}
          />
        ) : null}
      </div>
    ),
  };
  return (
    <div
      style={{
        ...style,
        background: participate ? 'rgb(232 228 125 / 49%)' : 'transparent',
        opacity: !participate && overlap ? 0.5 : 1,
        position: 'relative',
      }}
    >
      {/* <hr /> */}
      <div style={{ paddingRight: 10 }}>{icons[icon]}</div>
      <div>
        <Html html={time} style={{ fontWeight: 'bold' }} />
        <Html html={html} />
      </div>
      {/* <hr /> */}
    </div>
  );
};
Event.propTypes = {
  event: /** @type {import('prop-types').Validator<AXAEvent>} */ (
    PropTypes.object.isRequired
  ),
  participate: PropTypes.bool.isRequired,
  overlap: PropTypes.bool.isRequired,
  // index: PropTypes.number.isRequired,
  // size: PropTypes.number.isRequired,
};

const Overview = (props) => {
  const { roles, eventList } = props;
  const days = _(CALENDAR)
    .map((e, k) => {
      e.value = k;
      return e;
    })
    .sortBy((event) => moment(event.start))
    .groupBy((e) => moment.tz(e.start, 'Europe/Paris').format('dddd Do MMMM'))
    .reduce((result, events, day) => {
      result.push({ day, events });
      return result;
    }, []);
  return (
    <Card className="ui-card">
      <CardContent>
        <div style={{ fontSize: '16px' }}>
          <h1>Program</h1>
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <Markdown mdKey="overview" />
              </div>
            </div>
          </div>
          <div className="row">
            {days.map(({ day, events }, index) => (
              <div
                key={`overview-day-${index + 1}`} // eslint-disable-line react/no-array-index-key
                className="col-xs-12 col-sm-6"
              >
                <h3 style={{ fontSize: '22px' }}>
                  Day {index + 1} - {day}
                </h3>
                {events.map((event, i) => {
                  if (
                    (event.roles_overview &&
                      !roles.includes(event.roles_overview)) ||
                    event.html_overview === ''
                  ) {
                    return null;
                  }
                  /** @type {undefined | any} */
                  const userEvent = eventList.find(
                    (e) => e.value === event.value,
                  );
                  if (!userEvent) return null;
                  let participate = userEvent.selected;
                  // AXA GIS merged interactive
                  if (
                    !participate &&
                    event.value.includes('plen_interactive')
                  ) {
                    participate =
                      eventList.findIndex(
                        (e) =>
                          e.value.includes('plen_interactive') && e.selected,
                      ) >= 0;
                  }
                  return (
                    <Event
                      key={`overview-day-${index + 1}-event-${i}`} // eslint-disable-line react/no-array-index-key
                      event={event}
                      participate={participate}
                      overlap={userEvent.unavailable}
                      // index={i}
                      // size={events.length}
                    />
                  );
                })}
              </div>
            ))}
          </div>
          {PRIVATE_IM && (
            <span>
              <em>&nbsp;</em>
            </span>
          )}
         {/* <hr />
          * Some meetings are held twice due to potential overlaps for clients
          invested in multiple funds.
          <br />
          Please register for your preferred session only. &nbsp;
          <div className="axa--signature">&nbsp;</div> */}
        </div>
      </CardContent>
    </Card>
  );
};

Overview.prototype.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  eventList: PropTypes.arrayOf(PropTypes.object),
};

Overview.defaultProps = {
  roles: [],
  eventList: [],
};

/** @param {State} state */
const mapStateToProps = ({ profile, root }) => ({
  roles: profile && profile.roles,
  eventList: root.eventList,
});

export default connect(mapStateToProps)(Overview);
