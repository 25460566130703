import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';

class FileUploadButton extends Component {
  static propTypes = {
    handleAddFile: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    multiple: PropTypes.bool,
    onlyImage: PropTypes.bool,
    capture: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    label: '',
    multiple: false,
    onlyImage: false,
    capture: false,
  };

  onInputChange = e => {
    const { handleAddFile } = this.props;
    _.forEach(e.target.files, file => {
      handleAddFile(file);
    });
  };

  render() {
    const { disabled, label, multiple, onlyImage, capture } = this.props;

    const styles = {
      wrapper: { display: 'flex', justifyContent: 'center' },
      button: { overflow: 'hidden', position: 'relative' },
      inputFile: {
        opacity: '0',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
      },
    };
    return (
      <div style={styles.wrapper}>
        <Button
          variant="contained"
          component="div"
          disabled={disabled}
          style={styles.button}
        >
          <CloudUpload />
          {label}
          <input
            style={styles.inputFile}
            type="file"
            multiple={multiple}
            onChange={this.onInputChange}
            accept={onlyImage ? 'image/*' : ''}
            capture={capture}
          />
        </Button>
      </div>
    );
  }
}

export default FileUploadButton;
