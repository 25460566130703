import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import ical from 'ical-generator';
import moment from 'moment';
import { saveAs } from 'file-saver';

import config from '../../config/config.yaml';
import { getMenuItems } from '../../selectors/menu';

class Calendar extends React.PureComponent {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    eventList: PropTypes.arrayOf(PropTypes.shape()),
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    eventList: [],
  };

  static saveCalendar(events) {
    const cal = ical({
      domain: 'jasmin.resa-event.com',
      prodId: {
        company: 'Phileog Eleven',
        product: 'jasmin',
        language: 'EN', // optional, defaults to EN
      },
      name: config.name,
    }); // .timezone('Europe/Paris');
    events.forEach(e => {
      cal.createEvent({
        uid: `${config.resaKey}-${e.value}`,
        start: moment(e.start).toDate(),
        end: moment(e.stop).toDate(),
        summary: e.summary,
        // description: e.html, // ok google calendar, not outlook
        location:
          e.location ||
          'Hôtel Pullman Paris Tour Eiffel, 18 avenue de Suffren 75015 Paris',
        url: `https://jasmin.resa-event.com/${config.resaKey}/#/registration`,
      });
    });
    const ics = cal.toString();
    const icsFile = new Blob([ics], {
      type: 'text/calendar;charset=utf-8',
    });

    const fileSaver = saveAs(icsFile, 'calendar.ics');
    return new Promise(resolve => {
      fileSaver.onwriteend = resolve; // apparently always called (?)
    });
  }

  constructor(props) {
    super(props);
    const { menuItems } = props;
    this.state = {
      ready: false,
    };
    let mainFormTabIndex = _.findIndex(
      menuItems,
      item => item.mainForm === true,
    );
    mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
    this.loginHref = menuItems[mainFormTabIndex].href;
  }

  componentDidMount() {
    this.checkEvents();
  }

  componentDidUpdate() {
    this.checkEvents();
  }

  checkEvents() {
    const { ready } = this.state;
    const { eventList } = this.props;
    const { router } = this.context;
    if (ready) return;
    const events = eventList.filter(e => e.selected);
    if (events.length) {
      this.setState({ ready: true });
      Calendar.saveCalendar(events)
        .then(() => {
          router.history.push(this.loginHref);
        })
        .catch(() => {
          router.history.push(this.loginHref);
        });
    } else {
      router.history.push(this.loginHref);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ root, profile }) => ({
  eventList: root.eventList,
  menuItems: getMenuItems({ profile }),
});

export default connect(mapStateToProps)(Calendar);
