import defaultState from '../../content/index';

// this reduce will have actions if / when loading content becomes asynchronous
const content = (state = defaultState) => state;

export default content;

/*

// TODO : investigate https://webpack.js.org/guides/migrating/#code-splitting-with-es2015

const defaultState = import('../../../content/index'); // creates a bundle, defaultState is a Promise

const getContent = name =>
  import(`../../../content/${name}.md`).then(content => {
    console.log(content);
  });
  getContent('test');

*/
