import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const NEVER_SAW_CLOSE_TIME = 5000;
const ALREADY_SAW_CLOSE_TIME = 1000;
const ANIMATION_DURATION = 500;

class Splash extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    arrowColor: PropTypes.string,
  };

  static defaultProps = {
    backgroundColor: '',
    arrowColor: 'white',
  };

  static alreadySawIt() {
    return window.localStorage.getItem('splashSaw') === 'true';
  }

  constructor(props) {
    super(props);

    this.setStyles();

    this.state = {
      on: true,
    };

    // So on page reload we're on top and not as we were before
    window.addEventListener(
      'beforeunload',
      () => {
        window.scrollTo(0, 0);
      },
      false,
    );
  }

  /**
   * On splash screen mounted, initialize event listeners for:
   *  - Page resize: Height of the splash screen must match window height
   *  - wheel and click events: user wants to leav splash screen
   *  - the auto-close splash screen:
   *    -> It closes after a delay, which starts when the image is loaded (or fails to load)
   * @author Sylvain Pont
   */
  componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);

    // Start the autoClose timer ONLY once image is loaded
    if (this.image.complete) {
      this.autoClose();
    } else {
      this.image.addEventListener('load', this.autoClose);
      this.image.addEventListener('error', this.autoClose);
    }

    window.addEventListener('wheel', this.handleScrollOrClick, {
      passive: true,
    });
    window.addEventListener('click', this.handleScrollOrClick, false);
  }

  setStyles() {
    const { arrowColor, backgroundColor } = this.props;

    this.windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.styles = {
      splash: {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        height: `${this.windowHeight}px`,
        backgroundColor: _.isEmpty(backgroundColor)
          ? 'rgb(51, 51, 51)'
          : backgroundColor,
        backgroundImage: 'url(img/splash.jpg)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: _.isEmpty(backgroundColor) ? 'cover' : 'contain',
        position: 'fixed',
        transition: `transform ${ANIMATION_DURATION / 1000}s ease-in`,
        transform: 'translateY(0)',
        zIndex: '9999999',
        willChange: 'transform',
      },
      svgDownArrow: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        textAlign: 'center',
        width: '100%',
        height: '40px',
        color: arrowColor,
      },
      imageload: {
        visibility: 'hidden',
        width: '1px',
        height: '1px',
      },
    };
  }

  /**
   * If user scrolls or clicks, he wants the splash screen to be closed, force it
   * @author Sylvain Pont
   */
  handleScrollOrClick = () => {
    this.close();
  };

  /**
   * Automatically close self after delay (longer delay if never saw)
   * @author Sylvain Pont
   */
  autoClose = () => {
    let timeToClose = Splash.alreadySawIt()
      ? ALREADY_SAW_CLOSE_TIME
      : NEVER_SAW_CLOSE_TIME;
    timeToClose = _.isNil(window.animationWaited)
      ? timeToClose
      : timeToClose - window.animationWaited;
    timeToClose = timeToClose < 200 ? 200 : timeToClose;
    this.autoCloseTimer = _.delay(this.close, timeToClose);
  };

  /**
   * Close mean:
   * - clean all event listeners
   * - scroll down
   * - remove self
   * + Set splash screen as seen for next time shorter auto close delay
   * @author Sylvain Pont
   */
  close = () => {
    // Set splash screen as saw for next time uer visits (lower autoClose timer)
    window.localStorage.setItem('splashSaw', 'true');

    // Cancel remaining timer, if any
    clearTimeout(this.autoCloseTimer);

    // Remove all listeners
    window.removeEventListener('resize', this.handleResize, false);
    window.removeEventListener('wheel', this.handleScrollOrClick, {
      passive: true,
    });
    window.removeEventListener('click', this.handleScrollOrClick, false);

    // Remove the autoClose listener on image load
    this.image.removeEventListener('load', this.autoClose);
    this.image.removeEventListener('error', this.autoClose);

    // "scroll" the splash up then remove self
    this.splashWrapper.style.transform = 'translateY(-100vh)';
    setTimeout(this.remove, ANIMATION_DURATION + 100);
  };

  /**
   * Handle page resizing: splash height must match window height
   * @author Sylvain Pont
   */
  handleResize = () => {
    this.windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.splashWrapper.style.height = `${this.windowHeight}px`;
  };

  /**
   * Remove splash screen (state actually)
   * @author Sylvain Pont
   */
  remove = () => {
    this.setState({ on: false });
  };

  render() {
    const { on } = this.state;
    return (
      on && (
        <div
          key="splashScreen"
          style={this.styles.splash}
          ref={splashWrapper => {
            this.splashWrapper = splashWrapper;
          }}
        >
          {/* Hidden image (same as background), for loaded event */}
          <img
            alt="hidden"
            src="img/splash.jpg"
            style={this.styles.imageload}
            ref={image => {
              this.image = image;
            }}
          />
          <KeyboardArrowDown style={this.styles.svgDownArrow} />
        </div>
      )
    );
  }
}

export default Splash;
