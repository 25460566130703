import browserLocale from 'browser-locale';
import { addLocaleData } from 'react-intl';
import localeDataEn from 'react-intl/locale-data/en';
import localeDataFr from 'react-intl/locale-data/fr';
import localeDataDe from 'react-intl/locale-data/de';

import translations from '../../i18n/translations.json';

import CONFIG from '../../config/config.yaml';

const locale = browserLocale();

const init = () => {
  addLocaleData([...localeDataEn, ...localeDataFr, ...localeDataDe]);
};

const getLocale = () => {
  const lang = CONFIG.language ? CONFIG.language : locale;
  return lang.split('-')[0];
};

export { init, getLocale, translations };
