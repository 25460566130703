import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import jsonata from 'jsonata/jsonata-es5'; // because of UglifyJsPlugin

import CALENDAR from '../../config/calendar.json';

const UserData = props => {
  const { formValues, query, prefix, suffix, children } = props;
  const q = jsonata(query);
  // TODO: convert eventList and all mailing + badge vars
  const data = q.evaluate({
    ...formValues,
    calendar: CALENDAR /* , metrics */,
  });
  if (typeof data !== 'string' && typeof data !== 'number') return children; // TODO: support object / arrays
  return (
    <span>
      {prefix}
      {data}
      {suffix}
    </span>
  );
};

UserData.propTypes = {
  formValues: PropTypes.shape(),
  query: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  children: PropTypes.node,
};

UserData.defaultProps = {
  formValues: {},
  prefix: '',
  suffix: '',
  children: null,
};

const mapStateToProps = state => ({
  formValues: getFormValues('inscriptionForm')(state),
});

export default connect(mapStateToProps)(UserData);
