import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Markdown from '../../lib/SimpleContent/Markdown';

const Information = () => (
  <Card>
    <CardContent>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <div className="box">
            <Markdown mdKey="information" />
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
        <div className="box">
            <iframe
              title="Hôtel Pullman Paris Tour Eiffel"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.1330348878496!2d2.2906804158404794!3d48.85567350885183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6702219eadc43%3A0xb7764685dcef7ac5!2sH%C3%B4tel%20Pullman%20Paris%20Tour%20Eiffel!5e0!3m2!1sfr!2sfr!4v1676655582809!5m2!1sfr!2sfr"
              width="100%"
              height="300"
              allowFullScreen
              frameBorder="0"
            />
          </div>
        </div>
      </div>
      <div className="axa--signature">
        &nbsp;
      </div>
      <div className="row">
        <div
          className="col-xs-12"
          style={{ color: 'rgb(229,0,81)', fontWeight: 'bold', marginTop: 0 }}
        >
          <Markdown mdKey="information" attr="footer" />
        </div>
      </div>
    </CardContent>
  </Card>
);

export default Information;
