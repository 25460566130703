import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
    zIndex: '999',
  },
  progressWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const render = () => (
  <div style={styles.overlay}>
    <div style={styles.progressWrapper}>
      <CircularProgress size={80} thickness={2} />
    </div>
  </div>
);

export default render;
export { styles };
