import ReactGA from 'react-ga';
import URL from 'url';

import CONFIG from '../../config/config.yaml';
import MAILINGS from '../../config/mailings.yaml';

const SOCIAL_NETWORKS = {
  'fcbk': 'facebook',
  'twtr': 'twitter',
  'lnkd': 'linke-in',
  'yamr': 'yammer',
  'snap': 'snapchat',
  'inst': 'instagram',
  'goog': 'google+', // ???
}

/*

email : ?ref=em.invitation&qid=12/#invitation
social: ?ref=sn.fcbk#/invitation
flyer: ?ref=pp.qr

*/

// TODO: Remove query string !

const parsed = URL.parse(window.location.href, true);
const { ref, qid } = parsed.query; // ?ref=em.invitation&qid=12

const init = () => {
  if (!CONFIG.GA) return;
  ReactGA.initialize(CONFIG.GA, { debug: false, titleCase: false });
  ReactGA.set({
    appName: 'Jasmin',
    appVersion: `${CONFIG.resaKey}:1.0.0-dev`, // FIXME - get from package.json
    dimension1: CONFIG.resaKey,
  });
  if (ref) {
    const [source, mailing] = ref.split('.');
    let content = '';
    let medium = 'other';
    if (source === 'em') {
      medium = 'email';
      if (mailing) content = MAILINGS[mailing] && MAILINGS[mailing].title;
    } else if (source === 'sn') {
      medium = SOCIAL_NETWORKS[mailing];
      content = medium;
    } else if (source === 'pp') {
      medium = 'paper';
      content = mailing;
    }
    const queue = qid ? ` (#${qid})` : '';
    ReactGA.set({
      campaignSource: 'jasmin',
      campaignName: CONFIG.name,
      campaignMedium: medium,
      campaignContent: `${content}${queue}`,
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { init };
