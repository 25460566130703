import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

const StepperStep = props => {
  const {
    pKey,
    stepNumber,
    label,
    isActive,
    isVisited,
    isClickable,
    handleClick,
    stepContent,
    ...restProps // Needed to proxy MUI's props (which are not documented anywhere ...)
  } = props;
  const completed = !isActive && isVisited;
  const stepButton = isClickable ? (
    <StepButton
      key={`${pKey}-step-button}`}
      onClick={handleClick}
      icon={stepNumber}
    >
      {label}
    </StepButton>
  ) : (
    <StepLabel key={`${pKey}-step-label`} icon={stepNumber}>
      {label}
    </StepLabel>
  );
  const stepComponents = [stepButton];
  // If not vertical there won't be 'undefined' <Step> children that would throw errors
  if (stepContent) {
    stepComponents.push(
      <StepContent key={`${pKey}-step-content}`}>{stepContent}</StepContent>,
    );
  }
  return (
    <Step {...restProps} active={isActive} completed={completed}>
      {stepComponents}
    </Step>
  );
};

StepperStep.propTypes = {
  pKey: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isVisited: PropTypes.bool,
  isClickable: PropTypes.bool,
  handleClick: PropTypes.func,
  stepContent: PropTypes.node,
};

StepperStep.defaultProps = {
  isActive: false,
  isVisited: false,
  isClickable: false,
  handleClick: _.noop(),
  stepContent: null,
};

export default StepperStep;
