import Raven from 'raven-js';

import { SET_PROFILE_UID, SET_PROFILE, RESET } from './actions';

const DEFAULT_STATE = {
  uid: '',
  firstName: '',
  lastName: '',
  mail: '',
  roles: [],
  picture: null,
};

const setProfileUidReducer = (state, { uid }) => ({ ...state, uid });

const setProfileReducer = (state, { profile }) => {
  Raven.setUserContext({
    email: profile.mail,
    id: profile.uid,
  });
  return { ...state, ...profile };
};

const profileReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RESET:
      return DEFAULT_STATE;
    case SET_PROFILE_UID:
      return setProfileUidReducer(state, action);
    case SET_PROFILE:
      return setProfileReducer(state, action);
    default:
      return state;
  }
};

export default profileReducer;
