import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { FormattedMessage } from 'react-intl';

const Candidates = props => {
  const { name, candidatesData, selectCandidates, selectNone } = props;

  if (!candidatesData.length) return null;

  return (
    <div
      style={{
        backgroundColor: 'rgba(115, 37, 29, 0.10)',
        padding: '8px 16px',
        borderRadius: '5px',
      }}
    >
      <p>
        <FormattedMessage
          id="autocomplete.twin.candidates"
          defaultMessage={`The following {nbUsers, plural, one {user has} other {users have}} selected you to share their room.{br}
            Accept to finalize your registration or decline to choose another room mate.`}
          values={{ nbUsers: candidatesData.length, br: <br /> }}
        />
      </p>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        {candidatesData.map(candidateMember => (
          <div
            key={`autocomplete-${name}-candidates-${candidateMember.value}`}
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                flex: '1 0 0',
              }}
            >
              {candidateMember.label}
            </span>
            <Button
              onClick={() => {
                selectCandidates(candidateMember.value);
              }}
              style={{
                flex: '0 0 auto',
                width: 100,
                height: 28,
                backgroundColor: 'rgba(255,255,255,0.4)',
              }}
            >
              <FormattedMessage
                id="autocomplete.twin.accept"
                defaultMessage="Accept"
              />
            </Button>
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
          }}
        >
          <span style={{ flex: '1 0 0' }} />
          <Button
            onClick={() => {
              selectNone();
            }}
            style={{
              margin: '5px 0',
              width: 100,
              height: 28,
              backgroundColor: 'rgba(255,255,255,0.4)',
            }}
          >
            <FormattedMessage
              id="autocomplete.twin.decline"
              defaultMessage="Decline"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

Candidates.propTypes = {
  name: PropTypes.string.isRequired,
  candidatesData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectCandidates: PropTypes.func.isRequired,
  selectNone: PropTypes.func.isRequired,
};

export default Candidates;
