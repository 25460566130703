import React from 'react';
import { isNil as _isNil } from 'lodash';
import { FormattedMessage as T } from 'react-intl';

// https://emailregex.com/
export const emailRegex = /\b(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\b/gim;


export const requiredValidator = value =>
  _isNil(value) || value.length === 0 ? (
    <T id="validator.required" defaultMessage="Required" />
  ) : (
    undefined
  );

export const requiredToEqualValidator = equalValue => value =>
  _isNil(value) || value !== equalValue ? (
    <T id="validator.required" defaultMessage="Required" />
  ) : (
    undefined
  );

export const emailValidator = value =>
  // use match because https://stackoverflow.com/a/21373261/1830767
  value && !value.match(emailRegex) ? (
    <T id="validator.email" defaultMessage="Invalid email address" />
  ) : (
    undefined
  );

export const mobileValidator = value =>
  value &&
  !/^(\+\d{1,3}(\s?-\s?|\s)?)?(?!0+\s+,?$)(\d{2}[\s.-]?){5}$/.test(value) ? (
    <T id="validator.mobile" defaultMessage="Invalid mobile number" />
  ) : (
    undefined
  );

export const confirmValidator = confirmValue => value =>
  value && value !== confirmValue ? (
    <T id="validator.confirm" defaultMessage="Different from original" />
  ) : (
    undefined
  );
