import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import Lightbox from 'react-images';

import { disableSwipe, enableSwipe } from '../../redux/root/actionCreators';

import './slick.css';
import './slick-theme.css';
import './caroussel.css';

const generateCarouselImages = (
  imagesNames,
  imageUrlPrefix = '',
  { extensionDownload = false } = {},
) =>
  imagesNames.map(imageName => {
    const imagePath = `${imageUrlPrefix}/${imageName}`;
    const downloadName = extensionDownload
      ? imageName.replace('.jpg', extensionDownload)
      : imageName;
    const downloadPath = `${imageUrlPrefix}/${downloadName}`;
    return {
      src: imagePath,
      srcSmall: imagePath,
      thumbnail: imagePath,
      caption: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          download={downloadName.replace('/', '_')}
          href={downloadPath}
        >
          <img
            src="./img/file-download.svg"
            alt="download"
            height="16px"
            color="grey"
            style={{ verticalAlign: 'middle' }}
          />
          Télécharger
        </a>
      ),
    };
  });

class Carousel extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        srcSmall: PropTypes.string.isRequired,
        thumbnail: PropTypes.string,
        caption: PropTypes.any,
      }),
    ).isRequired,
    maxHeight: PropTypes.string,
    sideOverlap: PropTypes.string,
    isActive: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    maxHeight: null,
    sideOverlap: null,
    isActive: true,
  };

  constructor(props) {
    super(props);
    this.state = { lightboxIsOpen: false };
    this.loadedImages = [];

    this.settings = {
      slide: 'div',
      dots: false,
      arrows: true,
      draggable: false,
      infinite: true,
      speed: 300,
      initialSlide: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      focusOnSelect: false,
      centerMode: true,
      centerPadding: props.sideOverlap,
      adaptiveHeight: false,
      lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 480,
          settings: {
            centerPadding: '1px',
          },
        },
      ],
      afterChange: this.waitForImage,
    };
  }

  componentDidMount() {
    const { isActive } = this.props;
    if (!isActive) {
      this.slider.slickPause();
    }
  }

  componentDidUpdate(prevProps) {
    const { isActive: wasActive } = prevProps;
    const { isActive } = this.props;
    if (!wasActive && isActive) {
      this.sliderPlay();
    } else if (wasActive && !isActive) {
      this.slider.slickPause();
    }
  }

  openLightbox = idx => {
    this.setState({ lightboxIsOpen: true, openedImage: idx });
    if (this.slider) {
      this.slider.slickPause();
    }
  };

  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
    if (this.slider) {
      const { openedImage } = this.state;
      this.slider.slickGoTo(openedImage);
      this.sliderPlay();
    }
  };

  lightboxGotoPrevious = () => {
    const { images } = this.props;
    const { openedImage } = this.state;
    const nbImages = images.length;
    this.setState({
      openedImage: (openedImage - 1 + nbImages) % nbImages,
    });
  };

  lightboxGotoNext = () => {
    const { images } = this.props;
    const { openedImage } = this.state;
    const nbImages = images.length;
    this.setState({
      openedImage: (openedImage + 1) % nbImages,
    });
  };

  lightboxGotoImage = index => {
    this.setState({ openedImage: index });
  };

  sliderPlay = () => {
    const { isActive } = this.props;
    const { lightboxIsOpen } = this.state;
    if (isActive && !lightboxIsOpen) {
      if (this.slider.innerSlider.state.autoplaying === 'paused') {
        this.slider.slickPlay();
      }
    }
  };

  waitForImage = index => {
    if (!this.loadedImages.includes(index)) {
      this.slider.slickPause();
    }
  };

  loaded = index => {
    if (!this.loadedImages.includes(index)) {
      this.loadedImages.push(index);
      this.sliderPlay();
    }
  };

  render() {
    const { images, maxHeight, dispatch } = this.props;
    const { lightboxIsOpen, openedImage } = this.state;

    return (
      <div
        onTouchStart={() => {
          dispatch(disableSwipe());
        }}
        onTouchEnd={() => {
          dispatch(enableSwipe());
        }}
      >
        <div
          style={{
            margin: '25px',
          }}
        >
          <Slider
            {...this.settings}
            ref={slider => {
              this.slider = slider;
            }}
          >
            {images.map((image, idx) => (
              <div
                key={`slider-image-${idx}`} // eslint-disable-line react/no-array-index-key
                role="button"
                tabIndex={-1}
                onClick={() => this.openLightbox(idx)}
                // onTouchTap={() => this.openLightbox(idx)}
                onKeyPress={() => this.openLightbox(idx)}
              >
                <img
                  alt={`n°${idx}`}
                  src={image.srcSmall}
                  className="slick-image"
                  style={{
                    maxHeight: maxHeight || null,
                  }}
                  onLoad={() => {
                    this.loaded(idx);
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <Lightbox
          currentImage={openedImage}
          images={images}
          isOpen={lightboxIsOpen}
          onClickImage={this.lightboxGotoNext}
          onClickPrev={this.lightboxGotoPrevious}
          onClickNext={this.lightboxGotoNext}
          onClickThumbnail={this.lightboxGotoImage}
          onClose={this.closeLightbox}
          backdropClosesModal
          width={1920}
          showThumbnails
          theme={{
            container: {
              background: 'rgba(240, 240, 240, 1)',
            },
            arrow: {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              fill: '#222',
              opacity: 0.6,
              transition: 'opacity 200ms',
              ':hover': {
                opacity: 1,
              },
            },
            arrow__size__medium: {
              borderRadius: 40,
              height: 40,
              marginTop: -20,
              '@media (min-width: 768px)': {
                height: 70,
                padding: 15,
              },
            },
            arrow__direction__left: { marginLeft: 10 },
            arrow__direction__right: { marginRight: 10 },
            close: {
              fill: '#D40000',
              opacity: 0.6,
              transition: 'all 200ms',
              ':hover': {
                opacity: 1,
              },
            },
            footer: {
              color: 'black',
              fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
            },
            footerCount: {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            thumbnail: {},
            thumbnail__active: {
              boxShadow: '0 0 0 2px #00D8FF',
            },
          }}
        />
      </div>
    );
  }
}

export default connect()(Carousel);
export { generateCarouselImages };
