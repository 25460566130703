import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import gravatar from 'gravatar';

import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { getMenuItems } from '../../selectors/menu';

import { loginLogout } from '../../redux/root/actionCreators';

import CONFIG from '../../config/config.yaml';

import { application as applicationMessages } from '../../i18n/dynamic';

const styles = () => ({
  iconButtonRoot: {
    padding: 0,
    // width: 48,
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
  },
  circleRoot: {
    padding: 5,
  },
  avatarRoot: {
    width: 30,
    height: 30,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
  },
});

// TODO Was a pure component with shouldComponentUpdate ... Check if OK
class AvatarMenu extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    intl: intlShape.isRequired,
    // MUI Styles
    classes: PropTypes.object.isRequired,
    // REDUX
    dispatch: PropTypes.func.isRequired,
    hasCreds: PropTypes.bool,
    profile: PropTypes.shape(),
    hasUid: PropTypes.bool,
    firstName: PropTypes.string,
    mail: PropTypes.string,
    picture: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    hasCreds: false,
    profile: {},
    hasUid: false,
    firstName: null,
    mail: null,
    picture: null,
  };

  constructor(props) {
    super(props);
    const { menuItems } = props;
    this.handleLogout = this.handleLogout.bind(this);

    let mainFormTabIndex = _.findIndex(
      menuItems,
      item => item.mainForm === true,
    );
    mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
    this.loginHref = menuItems[mainFormTabIndex].href;

    this.state = { gravatarImg: null, open: false, anchorEl: null };
  }

  componentWillReceiveProps(nextProps) {
    const { mail } = this.props;
    const { mail: nextMail = '' } = nextProps;
    if (nextMail && nextMail !== mail) {
      const gravatarImg = gravatar.url(nextMail, {
        size: '30',
        default: '404',
      });
      fetch(gravatarImg).then(r => {
        if (r.status === 200) {
          this.setState({ gravatarImg });
        } else {
          this.setState({ gravatarImg: null });
        }
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { hasCreds, profile, hasUid, firstName } = this.props;
    const { gravatarImg, open, anchorEl } = this.state;

    return (
      nextProps.hasCreds !== hasCreds ||
      !_.isEqual(nextProps.profile, profile) ||
      nextProps.hasUid !== hasUid ||
      nextProps.firstName !== firstName ||
      nextState.gravatarImg !== gravatarImg ||
      nextState.open !== open ||
      nextState.anchorEl !== anchorEl
    );
  }

  handleLogout() {
    const { router } = this.context;
    const { dispatch } = this.props;

    router.history.push(this.loginHref);
    dispatch(loginLogout());
    this.handleClose();
  }

  handleToggleMenu = event => {
    const { open } = this.state;
    this.setState({ open: !open, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      hasCreds,
      // profile: { picture, firstName: defaultFirstName },
      hasUid,
      firstName,
      picture,
      intl,
    } = this.props;

    const { gravatarImg, open, anchorEl } = this.state;

    if (CONFIG.anonymous) {
      return null;
    }

    const hasAuthenticationData = hasCreds;
    const isAuthenticating = hasAuthenticationData && !hasUid;

    const avatarIcon = () => {
      if (!hasAuthenticationData) {
        return (
          <CircularProgress
            size={20}
            value={100}
            variant="static"
            classes={{ root: classes.circleRoot }}
          />
        );
      }
      if (isAuthenticating) {
        return (
          <CircularProgress size={20} classes={{ root: classes.circleRoot }} />
        );
      }
      const pic = picture || gravatarImg || undefined;
      const letter = (firstName || ' ').slice(0, 1);
      return (
        <Avatar src={pic} classes={{ root: classes.avatarRoot }}>
          {pic ? null : letter}
        </Avatar>
      );
    };

    const avatarButton = () => (
      <IconButton
        classes={{ root: classes.iconButtonRoot }}
        onClick={this.handleToggleMenu}
      >
        {avatarIcon()}
      </IconButton>
    );

    if (!hasAuthenticationData) {
      return <Link to={this.loginHref}>{avatarButton()}</Link>;
    }
    if (hasUid) {
      return (
        <>
          {avatarButton()}
          <Menu anchorEl={anchorEl} open={open} onClose={this.handleClose}>
            <MenuItem onClick={this.handleLogout}>{`${intl.formatMessage(
              applicationMessages.logout,
            )}`}</MenuItem>
          </Menu>
        </>
      );
    }
    return avatarButton();
  }
}

const mapStateToProps = ({ login, profile }) => ({
  hasCreds: !_.isEmpty(login.creds),
  profile: login.profile,
  hasUid: !_.isEmpty(profile.uid),
  firstName: profile.firstName,
  mail: profile.mail,
  picture: profile.picture,
  menuItems: getMenuItems({ profile }),
});

export default _.flow(
  withStyles(styles),
  connect(mapStateToProps),
  injectIntl,
)(AvatarMenu);
