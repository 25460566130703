import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import RehypeReact from 'rehype-react';

import Html from './Html';
import components from '../remark';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components,
}).Compiler;

const Markdown = props => {
  const { data, mdKey, attr, style: propsStyle } = props;
  const { [attr]: content = '', style: dataStyle } = data[mdKey];
  if (attr === 'htmlAst' && content) {
    return renderAst(content);
  }
  // Style from data overrides props one
  const style = _.isEmpty(dataStyle) ? propsStyle : dataStyle;
  return <Html html={content} style={style} />;
};

Markdown.prototype.propTypes = {
  data: PropTypes.shape(PropTypes.any).isRequired,
  mdKey: PropTypes.string.isRequired /* redux store key */,
  attr: PropTypes.string /* local front-matter key */,
  style: PropTypes.shape(),
};

Markdown.defaultProps = {
  attr: 'html',
  style: {},
};

const mapStateToProps = ({ content }) => ({
  data: content,
});

export default connect(mapStateToProps)(Markdown);
