import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';

import { getMenuItems } from '../../selectors/menu';

import { toggleLeftDrawer } from '../../redux/root/actionCreators';

import CUSTOMIZATION from '../../config/customization.yaml';

const { menu } = CUSTOMIZATION;

const { name, backgroundImage, footerImage } = menu;

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: backgroundImage ? `white url(${backgroundImage}) 50%` : 'white',
  },
  menuItemRoot: {
    color: theme.palette.primary.main,
  },
  menuItemGutters: {
    paddingLeft: '3rem',
  },
  subHeader: {
    // color: theme.palette.secondary.main,
  },
});

class AppMenu extends React.Component {
  static propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    // MUI Styles
    classes: PropTypes.object.isRequired,
    // REDUX
    leftDrawerOpened: PropTypes.bool.isRequired,
    userRoles: PropTypes.arrayOf(PropTypes.string),
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    userRoles: [],
  };

  constructor(props) {
    super(props);
    this.handleMenuItemClick = _.bind(this.handleMenuItemClick, this);
    this.handleToggleDrawer = _.bind(this.handleToggleDrawer, this);
    this.closeDrawer = _.bind(this.closeDrawer, this);
  }

  handleMenuItemClick(event, value, itemType) {
    if (itemType === 'tab') {
      event.preventDefault();
      event.stopPropagation();
      const { handleTabChange } = this.props;
      handleTabChange(event, value);
    }
    this.closeDrawer();
  }

  handleToggleDrawer(forceValue) {
    const { dispatch } = this.props;
    dispatch(toggleLeftDrawer(forceValue));
  }

  openDrawer = () => {
    this.handleToggleDrawer(true);
  };

  closeDrawer() {
    this.handleToggleDrawer(false);
  }

  render() {
    const { classes, leftDrawerOpened, userRoles, menuItems } = this.props;
    return (
      <SwipeableDrawer
        open={leftDrawerOpened}
        onOpen={this.openDrawer}
        onClose={this.closeDrawer}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div style={{ background: 'rgba(255,255,255,0.6)' }}>
          <ListSubheader classes={{ root: classes.subHeader }}>
            {name}
          </ListSubheader>
          {_.map(menuItems, (item, index) => {
            if (
              !_.isUndefined(item.role) &&
              !_.includes(userRoles, item.role)
            ) {
              return null;
            }
            return (
              <Link key={`menu-item-link-${index}`} to={`${item.href}`}>
                <MenuItem
                  key={`menu-item-${index}`}
                  onClick={event =>
                    this.handleMenuItemClick(event, index, item.type)
                  }
                  classes={{
                    gutters: classes.menuItemGutters,
                    root: classes.menuItemRoot,
                  }}
                >
                  {item.label}
                </MenuItem>
              </Link>
            );
          })}
        </div>
        {footerImage && (
          <img
            alt="menu footer"
            src={footerImage}
            style={{ position: 'absolute', bottom: '0', width: '100%' }}
          />
        )}
      </SwipeableDrawer>
    );
  }
}

const mapStateToProps = ({ root, profile }) => ({
  leftDrawerOpened: root.leftDrawerOpened,
  userRoles: profile.roles,
  menuItems: getMenuItems({ profile }),
});

export default _.flow(
  withStyles(styles),
  connect(mapStateToProps),
)(AppMenu);
