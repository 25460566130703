import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from 'react-intl';

import {
  emailRegex,
  emailValidator,
  confirmValidator,
} from '../../../lib/SummonForm/validators';
import { extractNameFromEmail } from '../../../lib/Utils';

import { BASE_RECIPIENT } from '../LoginMagicLink';

class Recipitents extends React.Component {
  static propTypes = {
    invitationText: PropTypes.string,
    onSetInvitationText: PropTypes.func.isRequired,
    recipients: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
    mustConfirmEmail: PropTypes.bool,
    invitationWithText: PropTypes.bool,
    handleSetRecipient: PropTypes.func.isRequired,
    forceShowNameFields: PropTypes.bool,
    asAdmin: PropTypes.bool,
  };

  static defaultProps = {
    invitationText: '',
    recipients: [],
    mustConfirmEmail: true,
    invitationWithText: false,
    forceShowNameFields: true,
    asAdmin: false,
  };

  componentDidMount() {
    this.input.addEventListener('paste', this.onPaste);
  }

  get canAddMultipleRecipients() {
    const { asAdmin } = this.props;
    return asAdmin;
  }

  get canAddRecipientName() {
    const { asAdmin } = this.props;
    return asAdmin;
  }

  get disabledAddRecipientName() {
    const { recipients } = this.props;
    const emailsAreSetAndValid = recipients.reduce(
      (res, { email, emailError }) => res && email && _.isNil(emailError),
      true,
    );
    return !this.canAddRecipientName || !emailsAreSetAndValid;
  }

  get canAddInvitationText() {
    const { asAdmin, invitationWithText } = this.props;
    return asAdmin && invitationWithText;
  }

  areRecipientsEmpty() {
    const { recipients } = this.props;
    return (
      _.isEmpty(recipients) ||
      recipients.every(recipient => _.isMatch(BASE_RECIPIENT, recipient))
    );
  }

  onAddEmail = () => {
    const { handleSetRecipient } = this.props;
    handleSetRecipient();
    /* TODO:
      then(() => {
        // Scroll certain amounts from current position
        window.scrollBy({
          top: 72, // could be negative value
          left: 0,
          behavior: 'smooth',
        });
      })
    */
  };

  onPaste = e => {
    const { recipients, handleSetRecipient, asAdmin } = this.props;

    // Only admins can paste multiple emails
    if (!asAdmin) return;
    if (!this.areRecipientsEmpty()) return;

    // Prevent the default pasting event and stop bubbling
    e.preventDefault();
    e.stopPropagation();

    // Get the clipboard data
    const paste = (e.clipboardData || window.clipboardData).getData('text');

    const almostEmails = (paste.match(/\b\S+@\S+\b/gim) || []).map(email =>
      email.toLowerCase(),
    );
    const emailFounds = (paste.match(emailRegex) || []).map(email =>
      email.toLowerCase(),
    );
    if (almostEmails.length !== emailFounds.length) {
      const fake = almostEmails.filter(email => !emailFounds.includes(email));
      // eslint-disable-next-line
      alert(`Bad emails ignored:\n ${JSON.stringify(fake, null, 2)}`);
      console.warn(fake);
    }

    if (_.isArray(emailFounds) && !_.isEmpty(emailFounds)) {
      const alreadyAddedEmails = recipients.map(({ email }) => email);
      const emailsToAdd = _(emailFounds)
        .uniq()
        .filter(email => !alreadyAddedEmails.includes(email))
        .value();
      _.forEach(emailsToAdd, (email, index) => {
        const newRecipient = { email };
        const { firstName, lastName } = extractNameFromEmail(email);
        if (firstName) newRecipient.firstName = firstName;
        if (lastName) newRecipient.lastName = lastName;
        handleSetRecipient(index, newRecipient);
      });
    }
    // TODO then scroll down
  };

  componentWillUnount() {
    this.input.removeEventListener('paste', this.onPaste);
  }

  handleChange(index, key, value) {
    const { recipients, handleSetRecipient } = this.props;
    const recipient = { ...recipients[index] };
    recipient[key] = value;
    if (key === 'email') {
      const emailError = (!_.isEmpty(value) && emailValidator(value)) || null;
      if (_.isNil(emailError)) {
        delete recipient.emailError;
        if (value && (!recipient.firstName || !recipient.lastName)) {
          const { firstName, lastName } = extractNameFromEmail(value);
          if (firstName && !recipient.firstName) {
            recipient.firstName = firstName;
          }
          if (lastName && !recipient.lastName) {
            recipient.lastName = lastName;
          }
        }
      } else {
        recipient.emailError = emailError;
      }
    }
    if (key === 'emailConfirmation' || key === 'email') {
      const { email, emailConfirmation } = recipient;
      const emailConfirmationError = confirmValidator(email)(emailConfirmation);
      if (_.isNil(emailConfirmationError)) {
        delete recipient.emailConfirmationError;
      } else {
        recipient.emailConfirmationError = emailConfirmationError;
      }
    }
    handleSetRecipient(index, recipient);
  }

  handleEmailChange = (index, e) => {
    e.persist();
    this.handleChange(index, 'email', e.target.value);
  };

  // sanitize on blur
  handleEmailBlur = (index, e) => {
    e.persist();
    const match = e.target.value.match(emailRegex);
    if (match) {
      this.handleChange(index, 'email', match[0].trim().toLowerCase());
    }
  };

  handleEmailConfirmationChange = (index, e) => {
    e.persist();
    this.handleChange(index, 'emailConfirmation', e.target.value);
  };

  handleFirstnameChange = (index, e) => {
    e.persist();
    this.handleChange(index, 'firstName', e.target.value);
  };

  handleLastnameChange = (index, e) => {
    e.persist();
    this.handleChange(index, 'lastName', e.target.value);
  };

  handleInvitationTextChange = e => {
    const { onSetInvitationText } = this.props;
    onSetInvitationText(e.target.value);
  };

  handleRemove = index => {
    const { handleSetRecipient } = this.props;
    handleSetRecipient(index, null);
  };

  render() {
    const {
      recipients,
      mustConfirmEmail,
      invitationText,
      forceShowNameFields,
    } = this.props;

    return (
      <div
        ref={input => {
          this.input = input;
        }}
      >
        {this.canAddInvitationText && (
          <TextField
            id="textarea-field-invitationText"
            label={
              <FormattedMessage
                id="form.invitation.text"
                defaultMessage="Invitation text"
              />
            }
            value={invitationText}
            multiline
            rows={2}
            rowsMax={5}
            onChange={this.handleInvitationTextChange}
            fullWidth
            margin="normal"
          />
        )}
        {recipients.map(
          (
            {
              email,
              emailError,
              emailConfirmation,
              emailConfirmationError,
              firstName,
              lastName,
            },
            index,
          ) => {
            const displayName =
              this.canAddRecipientName &&
              (forceShowNameFields ||
                firstName ||
                lastName ||
                (email && !emailError));
            return (
              <React.Fragment
                key={`invite-admin-emails-${index}`} // eslint-disable-line react/no-array-index-key
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'baseline',
                  }}
                >
                  <TextField
                    style={{ flex: '2 1 0' }}
                    label={
                      <FormattedMessage
                        id="form.email"
                        defaultMessage="Email adress"
                      />
                    }
                    placeholder="user@domain.com"
                    value={email}
                    error={!_.isNil(emailError)}
                    helperText={emailError}
                    type="email"
                    autoComplete="email"
                    // not ideal new function on every render
                    onChange={this.handleEmailChange.bind(this, index)} // eslint-disable-line react/jsx-no-bind
                    onBlur={this.handleEmailBlur.bind(this, index)} // eslint-disable-line react/jsx-no-bind
                    margin="normal"
                  />
                  {displayName && (
                    <>
                      <TextField
                        style={{ flex: '1 1 0' }}
                        label={
                          <FormattedMessage
                            id="form.firstname"
                            defaultMessage="Firstname"
                          />
                        }
                        value={firstName || ''}
                        autoComplete="given-name"
                        onChange={this.handleFirstnameChange.bind(this, index)} // eslint-disable-line react/jsx-no-bind
                        margin="normal"
                      />
                      <TextField
                        style={{ flex: '1 1 0' }}
                        label={
                          <FormattedMessage
                            id="form.lastname"
                            defaultMessage="Lastname"
                          />
                        }
                        value={lastName || ''}
                        autoComplete="family-name"
                        onChange={this.handleLastnameChange.bind(this, index)} // eslint-disable-line react/jsx-no-bind
                        margin="normal"
                      />
                    </>
                  )}
                  {recipients.length > 1 && (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="form.remove-email"
                          defaultMessage="Remove email"
                        />
                      }
                    >
                      <IconButton
                        onClick={this.handleRemove.bind(this, index)} // eslint-disable-line react/jsx-no-bind
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                {mustConfirmEmail && (
                  <TextField
                    label={
                      <FormattedMessage
                        id="form.confirmemail"
                        defaultMessage="Confirm email address"
                      />
                    }
                    placeholder="user@domain.com"
                    value={emailConfirmation}
                    error={!_.isNil(emailConfirmationError)}
                    helperText={emailConfirmationError}
                    type="email"
                    autoComplete="email"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={this.handleEmailConfirmationChange.bind(
                      this,
                      index,
                    )}
                    fullWidth
                    margin="normal"
                  />
                )}
              </React.Fragment>
            );
          },
        )}
        {this.canAddMultipleRecipients && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ margin: '0 10px' }}
              variant="contained"
              color="secondary"
              onClick={this.onAddEmail}
              disabled={this.disabledAddRecipientName}
            >
              +
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default Recipitents;
