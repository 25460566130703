import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setIsMobile } from '../../redux/root/actionCreators';

import CONFIG from '../../config/config.yaml';

const checkIsMobile = () => window.innerWidth < CONFIG.mobileSize;

// FIXME - Do we need a component for this?
// We need dispatch and addEventListener, move to index.js ?

const handleMobileDevices = WrappedComponent => {
  const componentHandlingMobileDevices = class extends React.PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.isMobile = null;
      this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidMount() {
      // Handle isMobile even if page gets resized
      this.setIsMobile();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    /**
     * The root component shouldn't normally be updated (unless history changes...)
     * @param  {object} nextProps
     * @return {bool}
     * @author Sylvain Pont
     */
    // shouldComponentUpdate() {
    //   return true;
    // }

    /**
     * Update isMobile redux store value
     * @author Sylvain Pont
     */
    setIsMobile() {
      const { dispatch } = this.props;
      const isMobile = checkIsMobile();
      if (isMobile !== this.isMobile) {
        dispatch(setIsMobile(isMobile));
        this.isMobile = isMobile;
      }
    }

    /**
     * Handle window resize event
     * @author Sylvain Pont
     */
    handleWindowSizeChange() {
      this.setIsMobile();
    }

    render() {
      // Note: if it does not inject any props, it probably should not be an HOC
      return <WrappedComponent {...this.props} />;
    }
  };

  componentHandlingMobileDevices.displayName = `handleMobileDevices(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'UnnamedComponent'})`;

  return connect()(componentHandlingMobileDevices);
};

export default handleMobileDevices;
