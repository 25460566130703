import React from 'react';
import PropTypes from 'prop-types';

import Markdown from '../../SimpleContent/Markdown';

const MarkdownContent = props => {
  const { name, field } = props;
  const { mdKey, ...input } = field;
  return <Markdown {...input} key={name} mdKey={mdKey} />;
};

MarkdownContent.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.shape({
    mdKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default MarkdownContent;
