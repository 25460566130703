import _ from 'lodash';

import {
  SET_FIRST_TIME_SUBMIT_FORM,
  SET_FIRST_TIME_FINISH_FORM,
  SET_VISITED_STEPS,
  SET_FINISHED,
  SET_SHOW_FORM,
  RESET_STEPS,
  RESET,
  BLOCK_FORM,
  RELEASE_FORM,
} from './actions';

export const resetForm = () => ({
  type: RESET,
});

export const setFirstTimeSubmitForm = firstTimeSubmitForm => ({
  type: SET_FIRST_TIME_SUBMIT_FORM,
  firstTimeSubmitForm,
});

export const setFirstTimeFinishForm = firstTimeFinishForm => ({
  type: SET_FIRST_TIME_FINISH_FORM,
  firstTimeFinishForm,
});

export function setStepsVisited(visitedSteps) {
  return {
    type: SET_VISITED_STEPS,
    visitedSteps,
  };
}

export function setFinished(finished = true) {
  return {
    type: SET_FINISHED,
    finished,
  };
}

export function setShowForm(showForm = true) {
  return {
    type: SET_SHOW_FORM,
    showForm,
  };
}

export function resetSteps() {
  return {
    type: RESET_STEPS,
  };
}

export function blockForm(name) {
  return {
    type: BLOCK_FORM,
    name,
  };
}

export function releaseForm(name) {
  return {
    type: RELEASE_FORM,
    name,
  };
}

export function initializeSummonForm(data) {
  return dispatch => {
    const { presence, steps } = data;

    // A submitted stepper have `steps` filled
    // `presence` field can only be filled if submitted
    // => Both of those conditions match submitted states, one for stepper, the other for not stepper

    // Stepper (and form submitted)
    if (_.isString(steps) && !_.isEmpty(steps)) {
      dispatch(setFirstTimeSubmitForm(false));
      const stepsArray = steps.split(',');
      const [finished, visitedSteps] = _.partition(stepsArray, s => s === '!');
      dispatch(setStepsVisited(visitedSteps));
      if (!_.isEmpty(finished)) {
        dispatch(setFirstTimeFinishForm(false));
        dispatch(setFinished());
        dispatch(setShowForm(false));
      }
    } else if (_.isNumber(presence)) {
      // Not stepper (and form submitted)
      dispatch(setFirstTimeSubmitForm(false));
      dispatch(setFirstTimeFinishForm(false));
      dispatch(setFinished());
      dispatch(setShowForm(false));
    }
  };
}

export function onSubmit() {
  return (dispatch, getState) => {
    const {
      summonForm: { firstTimeSubmitForm, firstTimeFinishForm, finished },
    } = getState();

    // Unset first times submit ...
    if (firstTimeSubmitForm) {
      dispatch(setFirstTimeSubmitForm(false));
    }
    // ... and finish
    if (firstTimeFinishForm && finished) {
      dispatch(setFirstTimeFinishForm(false));
    }

    if (finished) {
      dispatch(setShowForm(false));
    }
  };
}
