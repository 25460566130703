/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Card,
  CardContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Markdown from '../../lib/SimpleContent/Markdown';
import Html from '../../lib/SimpleContent/Html';

const Faq = ({ markdownContent }) =>
  markdownContent &&
  markdownContent.faq && (
    <Card>
      <CardContent>
        <Markdown mdKey="faq" />
        {markdownContent.faq.faq.map(({ open, title, content }) => (
          <ExpansionPanel key={`faq ${title}`} defaultExpanded={open}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Html html={content} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </CardContent>
    </Card>
  );

Faq.propTypes = {
  markdownContent: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ content }) => ({
  markdownContent: content,
});

export default connect(mapStateToProps)(Faq);
