import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SummonForm from '../../../lib/SummonForm/SummonForm';
import { getQuotaReached } from '../../../selectors/metrics';

import FORMS from '../../../config/forms.yaml';
import GRAPHQL from '../../../config/graphql.yaml';

class AdditionalFields extends React.Component {
  static propTypes = {
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
      .isRequired,
    canSubmit: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    // Redux
    isMobile: PropTypes.bool.isRequired,
    eventList: PropTypes.arrayOf(PropTypes.shape()),
    quotaReached: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    canSubmit: false,
    eventList: [],
  };

  handleSubmit = rawValues => {
    const { handleSubmit } = this.props;
    const mappings = FORMS.calendarMappings;
    const values = { ...rawValues };
    // prepare eventList values
    const events = GRAPHQL.schema.eventList
      ? GRAPHQL.schema.eventList.reduce(
          (acc, event) => ({ ...acc, [event]: 0 }),
          {},
        )
      : {};
    // convert mappings to events - see also mapValuesToQuery in actionCreators
    Object.keys(mappings).forEach(m => {
      if (values[m]) {
        events[values[m]] = 1;
        delete values[m];
      }
    });
    handleSubmit({ ...values, ...events });
  };

  render() {
    const {
      buttonLabel,
      canSubmit,
      isMobile,
      eventList,
      quotaReached,
    } = this.props;

    return (
      <SummonForm
        formName="invitationForm"
        formStructure={FORMS.invitation}
        calendarMappings={FORMS.calendarMappings}
        templateMappings={FORMS.templateMapping}
        graphQlInfos={GRAPHQL}
        onSubmit={this.handleSubmit}
        withSubmit
        buttonLabel={buttonLabel}
        canSubmit={canSubmit}
        embeded
        isMobile={isMobile}
        eventList={eventList}
        setSelectedEvents={this.setSelectedEvents}
        quotaReached={quotaReached}
      />
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.root.isMobile,
  eventList: state.root.eventList,
  quotaReached: getQuotaReached(state),
});

export default connect(mapStateToProps)(AdditionalFields);
