import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { getFormValues, getFormSyncErrors, submit } from 'redux-form';

import Stepper from '@material-ui/core/Stepper';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { summonForm as summonFormMessages } from '../../i18n/dynamic';

import Step from './Stepper/Step';
import { getHiddenSteps, getVisibleStepFields } from '../../selectors/form';

import {
  setStepsVisited,
  setFinished,
} from '../../redux/summonForm/actionCreators';

const styles = {
  actionsWrapper: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  actionsButtons: {
    flexGrow: '0',
    flexShrink: '1',
  },
  actionsProgress: {
    flexGrow: '1',
    flexShrink: '1',
    flexBasis: '0',
    margin: '0 20px',
  },
  actionsProgressStep: {
    flexGrow: '0',
    flexShrink: '1',
    margin: '0 20px 0 0',
  },
};

class SStepper extends React.Component {
  static propTypes = {
    formName: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        condition: PropTypes.shape(),
        fields: PropTypes.arrayOf(PropTypes.shape()),
      }),
    ).isRequired,
    renderFields: PropTypes.func.isRequired,
    clickable: PropTypes.bool,
    vertical: PropTypes.bool,
    navigationSubmit: PropTypes.bool,
    eventListRequired: PropTypes.bool,
    eventList: PropTypes.arrayOf(PropTypes.object),
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hiddenSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
    visibleStepFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
      .isRequired,
    // I18n
    intl: intlShape.isRequired,
    // Redux
    dispatch: PropTypes.func.isRequired,
    visitedSteps: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    blocked: PropTypes.bool,
    // Redux form
    formSyncErrors: PropTypes.shape(),
    formValues: PropTypes.shape(),
  };

  static defaultProps = {
    clickable: false,
    vertical: false,
    navigationSubmit: false,
    eventListRequired: false,
    eventList: [],
    visitedSteps: [],
    blocked: false,
    formSyncErrors: {},
    formValues: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };
    this.userHasNavigated = false;
  }

  componentWillMount() {
    // deprecated https://reactjs.org/docs/react-component.html#unsafe_componentwillmount
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { currentStep } = this.state;
    dispatch(setStepsVisited(currentStep));
  }

  componentWillReceiveProps(nextProps) {
    const { isLoading } = this.props;

    if (!this.userHasNavigated && isLoading && !nextProps.isLoading) {
      // defer so disabled steps that might be enabled on load will be enabled before going to said step
      // TODO: move to componentDidUpdate
      // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
      _.defer(() => this.goToStep(Math.max(...nextProps.visitedSteps)));
    }
  }

  // TODO shouldComponentUpdate (based on hiddenSteps ?)

  onNavigationBefore({ isFinished = false, nextStep } = {}) {
    this.userHasNavigated = true;
    const { formName, navigationSubmit, pristine, dispatch } = this.props;
    if (isFinished) {
      dispatch(setFinished());
      dispatch(submit(formName));
      return;
    }
    const isVisitedStep = this.isVisitedStep(nextStep);
    if (!isVisitedStep) {
      dispatch(setStepsVisited(nextStep));
    }
    if (navigationSubmit && (!pristine || !isVisitedStep)) {
      // Not finished but `navigationSubmit` is on and there is either modifications (!pristine) or `visitedSteps` (!isVisitedStep) to save
      dispatch(submit(formName));
    }
  }

  get isVertical() {
    const { vertical, isMobile } = this.props;
    return vertical || isMobile;
  }

  get stepFields() {
    const { steps } = this.props;
    const { currentStep } = this.state;
    return steps[currentStep].fields.map(step => step.name);
  }

  get nbSteps() {
    const { steps, hiddenSteps } = this.props;
    return steps.length - hiddenSteps.length;
  }

  // eslint-disable-next-line react/destructuring-assignment
  getStepCurrentIndex(stepIndex = this.state.currentStep) {
    const { steps, hiddenSteps } = this.props;
    const step = steps[stepIndex];
    return steps
      .filter((s, sIndex) => !_.includes(hiddenSteps, sIndex))
      .findIndex(s => _.camelCase(s.label) === _.camelCase(step.label));
  }

  handlePrev = () => {
    const { steps, hiddenSteps } = this.props;
    const { currentStep } = this.state;
    const nextStep = _.findLastIndex(
      steps,
      (s, sIndex) => sIndex < currentStep && !_.includes(hiddenSteps, sIndex),
    );
    this.navigateToStep(nextStep);
  };

  handleNext = () => {
    const { steps, hiddenSteps } = this.props;
    const { currentStep } = this.state;
    if (this.isLastStep()) {
      this.navigateToStep('finished');
    } else {
      const nextStep = steps.findIndex(
        (s, sIndex) => sIndex > currentStep && !_.includes(hiddenSteps, sIndex),
      );
      this.navigateToStep(nextStep);
    }
  };

  navigateToStep = step => {
    const isFinished = step === 'finished';
    this.onNavigationBefore({ isFinished, nextStep: step });
    if (!isFinished) {
      this.goToStep(step);
    }
  };

  goToStep = stepIndex => {
    if (stepIndex >= 0 && this.getStepCurrentIndex(stepIndex) < this.nbSteps) {
      this.setState({ currentStep: stepIndex });
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  isFirstStep(stepIndex = this.state.currentStep) {
    return this.getStepCurrentIndex(stepIndex) === 0;
  }

  // eslint-disable-next-line react/destructuring-assignment
  isLastStep(stepIndex = this.state.currentStep) {
    return this.getStepCurrentIndex(stepIndex) === this.nbSteps - 1;
  }

  isCurrentStep(stepIndex) {
    const { currentStep } = this.state;
    return currentStep === stepIndex;
  }

  // eslint-disable-next-line react/destructuring-assignment
  isVisitedStep(stepIndex = this.state.currentStep) {
    const { visitedSteps } = this.props;
    return _.includes(visitedSteps, stepIndex);
  }

  isCurrentStepInvalid() {
    const {
      blocked,
      submitting,
      formSyncErrors,
      visibleStepFields,
    } = this.props;
    const { currentStep } = this.state;
    return (
      blocked ||
      submitting ||
      _.intersection(visibleStepFields[currentStep], _.keys(formSyncErrors))
        .length > 0
    );
  }

  canSubmit() {
    const { eventListRequired, eventList, formValues } = this.props;
    // FIXME - formValues.accept is AXA only validation !!
    return formValues.accept && (
      this.nbSteps === 1 ||
      !eventListRequired ||
      !_.isEmpty(eventList.filter(e => e.selected))
    );
  }

  // eslint-disable-next-line react/destructuring-assignment
  renderStepFields = (stepIndex = this.state.currentStep) => {
    const { steps, renderFields } = this.props;
    return renderFields(steps[stepIndex].fields);
  };

  renderActionButtons = () => {
    const { intl } = this.props;
    const isFirstStep = this.isFirstStep();
    const isLastStep = this.isLastStep();
    const isCurrentStepInvalid = this.isCurrentStepInvalid();
    const { isVertical } = this;
    const showProgress = !isVertical;
    return (
      <div style={styles.actionsWrapper}>
        <div style={styles.actionsButtons}>
          {!isFirstStep && (
            <Button
              style={{ marginRight: 12 }}
              onClick={this.handlePrev}
              disabled={isCurrentStepInvalid}
            >
              {intl.formatMessage(summonFormMessages.back)}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 12 }}
            onClick={this.handleNext}
            disabled={isCurrentStepInvalid || (isLastStep && !this.canSubmit())}
          >
            {isLastStep
              ? intl.formatMessage(summonFormMessages.submit)
              : intl.formatMessage(summonFormMessages.next)}
          </Button>
        </div>
        {showProgress && (
          <LinearProgress
            style={styles.actionsProgress}
            variant="determinate"
            value={(this.getStepCurrentIndex() / this.nbSteps) * 100}
          />
        )}
        {showProgress && (
          <span style={styles.actionsProgressStep}>{`${intl.formatMessage(
            summonFormMessages.step,
          )} ${this.getStepCurrentIndex() + 1}/${this.nbSteps}`}</span>
        )}
      </div>
    );
  };

  renderStepContent() {
    return (
      <div>
        {this.renderStepFields()}
        {this.isLastStep() && !this.canSubmit() && (
          <div style={{ margin: '5px', textAlign: 'center', color: 'red' }}>
            You did not select any event to attend to.
          </div>
        )}
        {this.renderActionButtons()}
      </div>
    );
  }

  renderSteps = () => {
    const { formName, steps, clickable, hiddenSteps } = this.props;
    const isCurrentStepInvalid = this.isCurrentStepInvalid();
    const { isVertical } = this;
    const stepContent = isVertical && this.renderStepContent();
    return steps
      .filter((step, stepIndex) => !_.includes(hiddenSteps, stepIndex))
      .map((step, stepCurrentIndex) => {
        const { label } = step;
        const shortLabel = _.camelCase(label);
        const stepIndex = steps.findIndex(
          s => _.camelCase(s.label) === _.camelCase(label),
        );
        const isCurrentStep = this.isCurrentStep(stepIndex);
        const isVisitedStep = this.isVisitedStep(stepIndex);
        return (
          <Step
            key={`${formName}-step-${isVertical ? 'v' : 'h'}-${shortLabel}`}
            // Add v/h to refresh step button
            pKey={`${formName}-step-${isVertical ? 'v' : 'h'}-${shortLabel}`}
            stepNumber={stepCurrentIndex + 1}
            label={label}
            isActive={isCurrentStep}
            isVisited={isVisitedStep}
            isClickable={clickable && !isCurrentStepInvalid && !isCurrentStep}
            handleClick={() => this.navigateToStep(stepIndex)}
            stepContent={isCurrentStep && stepContent}
          />
        );
      });
  };

  render() {
    const { clickable } = this.props;
    const { currentStep } = this.state;

    return (
      <div>
        <Stepper
          activeStep={currentStep}
          nonLinear={clickable}
          orientation={this.isVertical ? 'vertical' : 'horizontal'}
        >
          {this.renderSteps()}
        </Stepper>
        {!this.isVertical && this.renderStepContent()}
      </div>
    );
  }
}

const mapStateToProps = (state, { formName }) => ({
  visitedSteps: state.summonForm.visitedSteps,
  blocked: state.summonForm.blocked,
  formValues: getFormValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  hiddenSteps: getHiddenSteps(formName)(state),
  visibleStepFields: getVisibleStepFields(formName)(state),
});

export default _.flowRight(
  connect(mapStateToProps),
  injectIntl,
)(SStepper);
