import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CSSTransitionGroup } from 'react-transition-group';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import IconCloudDownload from '@material-ui/icons/CloudDownload';
import IconCheck from '@material-ui/icons/Check';

import * as Utils from '../../../../Utils';

import {
  disableSwipe,
  enableSwipe,
} from '../../../../../redux/root/actionCreators';

const FileManagerImages = props => {
  const {
    filesBaseUrl,
    name,
    files,
    filesProgress,
    canRemove,
    handleRemove,
    multiple,
    dispatch,
  } = props;

  let component = null;
  if (!_.isEmpty(files)) {
    component = (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          width: multiple ? 'auto' : '200px',
        }}
        onTouchStart={() => {
          dispatch(disableSwipe());
        }}
        onTouchEnd={() => {
          dispatch(enableSwipe());
        }}
      >
        {multiple && <ListSubheader>Added images</ListSubheader>}
        <GridList
          key={`grd-${files.length}`}
          cols={multiple ? 1.2 : 1}
          cellHeight={180}
          padding={4}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            minWidth: '100%',
            justifyContent: 'space-between',
          }}
          className="ui-grid"
        >
          {files.map(({ meta, fileData }, index) => {
            const progress = filesProgress[meta.id];

            return (
              <GridListTile key={`${meta.id}-${files.length}`}>
                <img
                  alt={`upload number ${index + 1}`}
                  src={fileData || `${filesBaseUrl}/${meta.id}`}
                />
                <GridListTileBar
                  title={meta.name}
                  subtitle={Utils.humanFileSize(meta.size)}
                  style={{
                    background:
                      'linear-gradient(to top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)',
                  }}
                  actionIcon={
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row nowrap',
                      }}
                    >
                      <CSSTransitionGroup
                        key={`transition-${name}-${meta.id}-progress-done`}
                        transitionName="opacityTransition"
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                      >
                        {_.isUndefined(progress) && (
                          <div
                            key={`${meta.id}-progress`}
                            style={{
                              position: 'absolute',
                              height: '16px',
                              left: '5px',
                              bottom: 'calc(180px - 16px - 5px - 2px)',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <IconCheck
                              style={{
                                width: '16px',
                                height: '16px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                padding: '2px',
                                color: 'rgba(0, 255, 0, 0.8)',
                              }}
                            />
                          </div>
                        )}
                      </CSSTransitionGroup>
                      <div style={{ width: '48px', height: '48px' }}>
                        <CSSTransitionGroup
                          key={`transition-${name}-${
                            meta.id
                          }-progress-download`}
                          transitionName="opacityTransition"
                          transitionEnterTimeout={300}
                          transitionLeaveTimeout={300}
                        >
                          {_.isUndefined(progress) ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${filesBaseUrl}/${meta.id}`}
                              style={{ position: 'absolute' }}
                            >
                              <IconButton>
                                <IconCloudDownload
                                  style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                                />
                              </IconButton>
                            </a>
                          ) : (
                            <div
                              key={`${meta.id}-progress`}
                              style={{
                                position: 'absolute',
                                padding: '12px',
                              }}
                            >
                              <CircularProgress
                                variant={
                                  progress ? 'determinate' : 'indeterminate'
                                }
                                value={progress}
                                size={24}
                                style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                              />
                            </div>
                          )}
                        </CSSTransitionGroup>
                      </div>
                      {canRemove ? (
                        <IconButton onClick={() => handleRemove(meta.id)}>
                          <IconDelete
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                          />
                        </IconButton>
                      ) : null}
                    </div>
                  }
                />
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  }

  return (
    <CSSTransitionGroup
      key={`transition-${name}`}
      transitionName="opacityTransition"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {component}
    </CSSTransitionGroup>
  );
};

FileManagerImages.propTypes = {
  filesBaseUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.shape,
    }),
  ).isRequired,
  filesProgress: PropTypes.shape(),
  canRemove: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func,
  multiple: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

FileManagerImages.defaultProps = {
  handleRemove: _.noop,
  filesProgress: {},
  multiple: false,
};

export default connect()(FileManagerImages);
