import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FileDrop from 'react-file-drop';

class FileDropZone extends Component {
  static propTypes = {
    handleAddFile: PropTypes.func.isRequired,
  };

  handleFileDrop = files => {
    const { handleAddFile } = this.props;
    _.forEach(files, file => {
      handleAddFile(file);
    });
  };

  handlFileDropOnce = _.throttle(this.handleFileDrop, 100, {
    trailing: false,
  });

  render() {
    const styles = {
      border: '1px solid black',
      width: 600,
      color: 'black',
      padding: 20,
    };
    return (
      <div className="my-uploader" style={{ styles }}>
        <FileDrop
          frame={document}
          onDrop={this.handlFileDropOnce}
          targetAlwaysVisible
        >
          Or drop some files here!
        </FileDrop>
      </div>
    );
  }
}

export default FileDropZone;
