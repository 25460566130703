import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { FormattedMessage as T } from 'react-intl';

import Radio from './Radio';

const yesNo = [
  { value: 1, label: <T id="toggle.yes" defaultMessage="Yes" /> },
  { value: 0, label: <T id="toggle.no" defaultMessage="No" /> },
];

const styles = theme => ({
  fullWidthOneLine: {
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
});

const ToggleRadioField = props => {
  const { name, disabled, field, classes } = props;
  return (
    <Radio
      name={name}
      disabled={disabled}
      field={{
        options: yesNo,
        isInt: true,
        row: true,
        rowLabel: true,
        classes: {
          formControlLabel: { labelPlacementStart: classes.fullWidthOneLine },
        },
        ...field,
      }}
    />
  );
};

ToggleRadioField.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

ToggleRadioField.prototype.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(ToggleRadioField);
