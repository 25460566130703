import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import {
  isPristine,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
} from 'redux-form';

import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { summonForm as summonFormMessages } from '../../i18n/dynamic';

const styles = theme => ({
  snackbar: {
    zIndex: theme.zIndex.modal - 1,
  },
  snackbarBody: {
    backgroundColor: 'rgb(245, 245, 245)',
    boxShadow: 'rgb(51, 51, 51) 0px 0px 10px 0px',
    borderRadius: '3px 3px 0 0',
  },
  // For when there is the progress bar
  snackbarBigBody: {
    height: '68px',
  },
  snackbarContent: {
    color: 'rgb(0,0,0)',
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  snackbarSuccessContent: {
    color: 'rgb(64, 157, 0)',
  },
});

class FormState extends React.Component {
  static propTypes = {
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    intl: intlShape.isRequired,
    // Styles
    classes: PropTypes.shape({
      snackbar: PropTypes.string,
      snackbarBody: PropTypes.string,
      snackbarBigBody: PropTypes.string,
      snackbarContent: PropTypes.object,
      snackbarSuccessContent: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    pristine: true,
    invalid: false,
    submitting: false,
    submitSucceeded: false,
  };

  state = {
    submitSucceeded: false,
  };

  /**
   * On form state changes:
   *  - If dirty: set an alert on application before unload (if user closes tab or ...)
   *  - If submit succeeded: as we trick state for submitSucceeded state not to last,
   *      (form can be modified again, with a new pristine state)
   *      let's remember the succeeded state for 2 seconds
   *    => Succeed message will last 2 seconds
   * @param  {object} nextProps
   * @author Sylvain Pont
   */
  componentWillReceiveProps(nextProps) {
    const { submitSucceeded } = this.props;
    if (!nextProps.pristine || nextProps.submitting) {
      window.onbeforeunload = e => e;
    } else {
      window.onbeforeunload = null;
    }
    if (nextProps.submitSucceeded && !submitSucceeded) {
      this.setState({ submitSucceeded: true });
      _.delay(_.bind(this.setState, this), 2000, { submitSucceeded: false });
    }
  }

  render() {
    const { submitSucceeded } = this.state;
    const { pristine, invalid, submitting, intl, classes } = this.props;
    return (
      <div>
        {/* Show reminder for user to submit a modified and valid form */}
        <Snackbar
          open={!pristine && !invalid && !submitting && !submitSucceeded}
          message={intl.formatMessage(summonFormMessages.dontForget)}
          ContentProps={{
            classes: {
              root: classes.snackbarBody,
              message: classes.snackbarContent,
            },
          }}
          classes={{ root: classes.snackbar }}
        />
        {/* Show progress bar on submitting */}
        <Snackbar
          open={submitting}
          message={
            submitting && (
              <div>
                <FormattedMessage
                  id="formstate.sending"
                  defaultMessage="Sending..."
                />
                <LinearProgress />
              </div>
            )
          }
          ContentProps={{
            classes: {
              root: `${classes.snackbarBody} ${classes.snackbarBigBody}`,
              message: classes.snackbarContent,
            },
          }}
          classes={{ root: classes.snackbar }}
        />
        {/* Show submitSucceeded message */}
        <Snackbar
          open={submitSucceeded}
          message={intl.formatMessage(summonFormMessages.thankSubmitted)}
          // autoHideDuration is set within the state @see componentWillReceiveProps
          ContentProps={{
            classes: {
              root: classes.snackbarBody,
              message: `${classes.snackbarContent} ${
                classes.snackbarSuccessContent
              }`,
            },
          }}
          classes={{ root: classes.snackbar }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  pristine: isPristine(props.formName)(state),
  invalid: isInvalid(props.formName)(state),
  submitting: isSubmitting(props.formName)(state),
  submitSucceeded: hasSubmitSucceeded(props.formName)(state),
});

export default _.flowRight(
  connect(mapStateToProps),
  injectIntl,
  withStyles(styles),
)(FormState);
