import Welcome from './Pages/Welcome';
import Information from './Pages/Information';
import MainForm from './Form/MainForm';
import InscriptionsClosed from './Form/InscriptionsClosed';
import Invite from './Pages/Invite';
import Admin from './Pages/Admin';
import Video from './Pages/Video';
import Faq from './Pages/Faq';
import Overview from './Pages/Overview';

export default {
  Welcome,
  Information,
  MainForm,
  InscriptionsClosed,
  Invite,
  Admin,
  Video,
  Faq,
  Overview,
};
