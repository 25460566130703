import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import cx from 'classnames';

import CUSTOMIZATION from '../../config/customization.yaml';

const small = '@media only screen and (max-width: 600px)';
// TODO: move all scss to styles?

const styles = {
  img: {
    title: {
      flex: 'auto 0 0',
    },
    logo1: {
      flex: 'auto 0 0',
    },
    logo2: {
      flex: 'auto 0 0',
    },
  },
  wrapper: {
    height: '100%',
    // paddingTop: 30,
    maxWidth: 780,
    margin: 'auto',
    boxSizing: 'border-box',
  },
  inside: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '0 10px 0 0',
  },
  spacer: {
    // flexBasis: '100%',
    flexGrow: 1,
  },
  gsSpacer1: {
    flex: '0 0 0',
    [small]: {
      flex: '35px 0 0',
    },
  },
  gsSpacer2: {
    flex: '49px 0 0',
    [small]: {
      flex: '14px 0 0',
    },
  },
};

/**
 * This component propose as simple as possible header
 * => will probably be rewritten for each project
 */
const Header = ({ classes }) => {
  const {
    header: { notSticky = {} },
  } = CUSTOMIZATION;
  const {
    title,
    // titleImages: [imgTitle],
    style = {},
    titleStyle = {},
  } = notSticky;
  return (
    <div style={style} className={cx('axa--header')} id="#header">
      <div className={cx('axa--header--wrapper', classes.wrapper)}>
        <div className={classes.inside}>
          {/* <div className={cx(classes.spacer, 'axa--header--spacer-1')} /> */}
          {/* <img
            alt="RE/Generate"
            src={imgTitle}
            className={cx(classes.img.title)}
          /> */}
          {/* <div className={cx(classes.spacer, 'axa--header--spacer-2')} /> */}
          {/* {titleImage && (
            <img
              alt="Title side"
              src={titleImage}
              className="hide-on-phone"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          )} */}
          {title ? (
            <h1
              style={titleStyle}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(Header);
