import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static trackPageView(location) {
    // give UI a chance to update the title
    // TODO: optimize
    requestAnimationFrame(() => {
      setTimeout(() => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      }, 500);
    });
  }

  componentDidMount() {
    const {
      router: { history },
    } = this.context;
    GAListener.trackPageView(history.location);
    history.listen(GAListener.trackPageView);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default GAListener;
