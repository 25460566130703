import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GraphiQL from 'graphiql';

import 'graphiql/graphiql.css';

import CONFIG from '../config/config.yaml';
import GRAPHQL from '../config/graphql.yaml';

class GQL extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  fetcher = ({ query, variables } = {}) => {
    const { dispatch } = this.props;
    return dispatch((_dispatch, getState, { aws }) => {
      const payload = {
        body: {
          query,
          variables,
          resaKey: CONFIG.resaKey,
        },
      };
      return aws
        .ready()
        .then(() => aws.invokeLambda(payload))
        .then(result => result.body);
    });
  };

  render() {
    const variables = JSON.stringify({ uid: 'BJsRn1BEnKW' });
    return (
      <div>
        <GraphiQL
          fetcher={this.fetcher}
          query={GRAPHQL.query}
          variables={variables}
        />
        {/* <button onClick={this.invoke} /> */}
      </div>
    );
  }
}

export default connect()(GQL);
