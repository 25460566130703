import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { loginReducer as login, LoginRelay } from 'phileog-login-client';

import CONFIG from '../config/config.yaml';

import root from './root/reducer';
import content from './content/reducer';
import summonForm from './summonForm/reducer';
import profile from './profile/reducer';
import Aws from '../lib/lambda';
import { onLogin, onLogout } from './root/actionCreators';

const STAGING = false; // used for SNCF login, stages uses test accounts

// FIXME - find a way to include stage (login-stage)
const loginUrl =
  process.env.LOGIN_ENV === 'development'
    ? 'http://localhost:3030/'
    : `https://login${STAGING ? '-stage' : ''}.phileog.com/`;
// note: it _must_ be called loginRelay
const loginRelay = new LoginRelay({
  resaKey: CONFIG.resaKey,
  url: loginUrl,
  onLoginAction: onLogin, // pass action creators
  onLogoutAction: onLogout,
}); // https://login.phileog.com/
const aws = new Aws();

const store = createStore(
  combineReducers({
    root,
    form,
    content,
    summonForm,
    profile,
    login,
  }),
  compose(
    applyMiddleware(thunk.withExtraArgument({ loginRelay, aws })),
    // redux-dev-tools browser extension
    //   window === 'object' to handle native react, if any
    typeof window === 'object' &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' // eslint-disable-line no-underscore-dangle
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) // eslint-disable-line no-underscore-dangle
      : f => f,
  ),
);

// start the login relay
loginRelay.init(store.dispatch);

export default store;
