import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CSSTransitionGroup } from 'react-transition-group';

import FileManagerClassic from './FileManager/FileManagerClassic';
import FileManagerImages from './FileManager/FileManagerImages';

class FileManager extends Component {
  static propTypes = {
    assetsBaseUrl: PropTypes.string.isRequired,
    filesBaseUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        meta: PropTypes.shape,
      }),
    ).isRequired,
    filesProgress: PropTypes.shape(),
    handleRemove: PropTypes.func,
    multiple: PropTypes.bool,
    onlyImage: PropTypes.bool,
  };

  static defaultProps = {
    handleRemove: _.noop,
    filesProgress: {},
    multiple: false,
    onlyImage: false,
  };

  canRemove() {
    const { handleRemove } = this.props;
    return handleRemove !== _.noop;
  }

  render() {
    const {
      assetsBaseUrl,
      filesBaseUrl,
      name,
      files,
      filesProgress,
      handleRemove,
      multiple,
      onlyImage,
    } = this.props;
    const canRemove = this.canRemove();
    const style = {
      fileManager:
        onlyImage && !multiple
          ? { marginRight: '20px' }
          : { marginBottom: '5px' },
    };

    let component = null;
    if (!_.isEmpty(files)) {
      if (onlyImage) {
        component = (
          <div style={style.fileManager}>
            <FileManagerImages
              name={name}
              files={files}
              filesProgress={filesProgress}
              canRemove={canRemove}
              handleRemove={handleRemove}
              filesBaseUrl={filesBaseUrl}
              multiple={multiple}
            />
          </div>
        );
      } else {
        component = (
          <div style={style.fileManager}>
            <FileManagerClassic
              name={name}
              files={files}
              filesProgress={filesProgress}
              canRemove={canRemove}
              handleRemove={handleRemove}
              filesBaseUrl={filesBaseUrl}
              assetsBaseUrl={assetsBaseUrl}
            />
          </div>
        );
      }
    }

    return (
      <CSSTransitionGroup
        key={`transition-${name}`}
        transitionName="opacityTransition"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {component}
      </CSSTransitionGroup>
    );
  }
}

export default FileManager;
