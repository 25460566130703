import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import MagicLink from '../Login/LoginMagicLink';

class Invite extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  componentWillMount() {
    const { userRoles } = this.props;
    const { router } = this.context;
    if (!_.includes(userRoles, 'admin')) {
      router.history.push('/registration');
    }
  }

  render() {
    return (
      <div className="tab">
        <Card>
          <CardContent>
            <MagicLink asAdmin />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => ({
  userRoles: profile.roles,
});

export default connect(mapStateToProps)(Invite);
