/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import _ from 'lodash';

import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { getMetrics } from '../../redux/root/actionCreators';

import CONFIG from '../../config/config.yaml';

const COLORS = ['#0088FE', '#F45D01', '#EEB902', '#82CA9D', '#474647'];

const adminConfig = _.memoize(() => {
  const kms = new AWS.KMS();
  return new Promise(resolve => {
    kms.decrypt(
      { CiphertextBlob: Buffer.from(CONFIG.admin, 'base64') },
      (err, data) => {
        if (err) throw new Error(err);
        const s = data.Plaintext.toString('utf8');
        resolve(JSON.parse(s));
      },
    );
  });
});

class Admin extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    uid: PropTypes.string.isRequired,
    metrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.download = this.download.bind(this);
    this.state = {
      identityId: null,
      reportings: [],
    };
  }

  componentDidMount() {
    const { userRoles = [] } = this.props;
    if (!_.includes(userRoles, 'admin')) {
      this.context.router.history.push('/inscription');
      return;
    }
    this.getReportings();
  }

  componentDidUpdate(prevProps) {
    const { uid, userRoles = [] } = this.props;
    if (uid !== prevProps.uid && userRoles.includes('admin'))
      this.getReportings();
  }

  getData() {
    const data = _.mapKeys(
      _.keyBy(this.props.metrics, 'role'),
      (value, key) => key,
    ); // use role !
    if (!data.total) return {};
    return {
      total: data.total,
      presence: [
        {
          name: data.confirmed.label,
          value: data.confirmed.value,
        },
        {
          name: data.declined.label,
          value: data.declined.value,
        },
        {
          name: data.pending.label,
          value: data.pending.value,
        },
      ],
      // soiree: [
      //   {
      //     name: data.soiree.label,
      //     value: data.soiree.value,
      //   },
      // ],
    };
  }

  getReportings() {
    const { uid } = this.props;
    if (!uid || !AWS.config.credentials) return;
    AWS.config.credentials.adminUpgrade();
    adminConfig().then(r => {
      const reportings = r.reporting
        .filter(
          r =>
            typeof r === 'string' ||
            (Array.isArray(r.uid) ? r.uid.includes(uid) : r.uid === uid),
        )
        .map(r => (typeof r === 'string' ? r : r.sheetId));
      const datastudio = r.datastudio;
      this.setState({ reportings, datastudio });
    });
  }

  async download(index = 0) {
    // https://stackoverflow.com/questions/40845937/aws-user-pool-how-to-edit-user-attributes
    // https://docs.aws.amazon.com/cognito/latest/developerguide/role-based-access-control.html
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#getSignedUrl-property
    // https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_condition.html
    // see getOpenIdToken for actual claims ??
    // For Google, we need to fix this: https://docs.aws.amazon.com/cognitoidentity/latest/APIReference/API_SetIdentityPoolRoles.html
    // cmdline : https://docs.aws.amazon.com/cli/latest/reference/cognito-identity/set-identity-pool-roles.html
    const { reportings } = this.state;
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      region: 'eu-west-3', // FR: eu-west-3
    });
    s3.getSignedUrl(
      'getObject',
      {
        Bucket: 'resa-fr.phileogeleven.com',
        Key: `resa/export/${CONFIG.resaKey}/${reportings[index]}.xlsx`,
      },
      (err, url) => {
        if (err) {
          console.warn(err);
          this.setState({ identityId: AWS.config.credentials.identityId });
        }
        if (url) window.location.assign(url);
      },
    );

    // s3.getObject(
    //   {
    //     Bucket: 'resa-fr.phileogeleven.com',
    //     Key: `resa/export/${CONFIG.resaKey}/${CONFIG.reporting[0]}.xlsx`,
    //   },
    //   (err, data) => {
    //     if (err)
    //       console.log(err, err.stack); // an error occurred
    //     else console.log(data);
    //   },
    // );
  }

  render() {
    const data = this.getData();
    const { identityId, reportings, datastudio } = this.state;

    if (_.isEmpty(data)) {
      // return null;
    }

    // const RADIAN = Math.PI / 180;
    // const renderCustomizedLabelInCell = props => {
    //   const {
    //     cx,
    //     cy,
    //     midAngle,
    //     innerRadius,
    //     outerRadius,
    //     percent,
    //     name,
    //   } = props;
    //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //   const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //   return (
    //     <text
    //       x={x}
    //       y={y}
    //       fill="#333"
    //       textAnchor={'middle'}
    //       dominantBaseline="central"
    //     >
    //       {name}
    //     </text>
    //   );
    // };

    const renderCustomizedLabelName = props => {
      const { x, y, fill, name } = props;
      return (
        <text
          x={x}
          y={y}
          fill={fill}
          textAnchor={'start'}
          dominantBaseline="ideographic"
        >
          {name}
        </text>
      );
    };

    return (
      <div className="tab">
        {datastudio ? (
          <Card className="ui-card">
            <iframe
              width="780"
              height="616"
              src={datastudio}
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </Card>
        ) : null}
        <Card key={name}>
          <CardContent>
            {datastudio ? null : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <table>
                  <tbody>
                    {_.map(this.props.metrics, (entry, index) => (
                      <tr
                        key={`metrics-entry-${entry.role}`}
                        style={{ color: COLORS[(index - 1) % COLORS.length] }}
                      >
                        <td>{entry.label}:</td>
                        <td>{entry.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PieChart width={300} height={300}>
                  <Pie
                    dataKey="value"
                    data={data.presence}
                    innerRadius={40}
                    outerRadius={80}
                    startAngle={0}
                    endAngle={360}
                  >
                    // label={renderCustomizedLabelName}
                    // labelLine={false}
                    {_.map(data.presence, (entry, index) => (
                      <Cell
                        key={`pie-cell-metrics-entry-${entry.name}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  {/* <Pie
                  dataKey="value"
                  data={data.soiree}
                  innerRadius={85}
                  outerRadius={90}
                  startAngle={0}
                  endAngle={data.soiree[0].value / data.total.value * 360}
                  fill="#82ca9d"
                  label={renderCustomizedLabelName}
                /> */}
                  <Tooltip />
                </PieChart>
              </div>
            )}
            {reportings.length
              ? identityId ||
                reportings.map((r, i) => (
                  <Button
                    key={r}
                    // href={`export/${CONFIG.reporting[0]}.xlsx`}
                    // target="_blank"
                    onClick={() => this.download(i)}
                    style={{ marginRight: '10px' }}
                  >
                    <Icon color="primary">file_download</Icon>
                    <span style={{ color: 'rgb(135,135,135)' }}>
                      {`Reporting ${i + 1}`}
                    </span>
                  </Button>
                ))
              : null}
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ profile, root }) => ({
  userRoles: profile.roles,
  uid: profile.uid,
  metrics: root.metrics,
});

export default connect(mapStateToProps)(Admin);
