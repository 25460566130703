import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { change, getFormInitialValues } from 'redux-form';

import { Tooltip, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

import InputSuggest from './AutoComplete/InputSuggest';
import Candidates from './Twin/Candidates';
import TwinToggle from './Twin/TwinToggle';

class Twin extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    field: PropTypes.shape().isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    room: PropTypes.shape(),
    executeQuery: PropTypes.func.isRequired,
    formInitial: PropTypes.shape(),
    roles: PropTypes.arrayOf(PropTypes.string),
    blockForm: PropTypes.func.isRequired,
    releaseForm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    room: {},
    value: '',
    formInitial: {},
    roles: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      candidatesData: [],
      loading: true,
      forcedValue: !_.isEmpty(props.value) ? props.value : null,
      choseAction: null,
    };
  }

  componentDidMount() {
    const { formInitial, value, blockForm, name } = this.props;
    if (!_.isNil(formInitial.uid)) {
      this.sideEffects();
    }
    if (_.isEmpty(value) && this.isRequired()) {
      blockForm(name);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    // really necessary to 'fork' props.value ?
    if (nextProps.value !== value) {
      this.setState({ forcedValue: nextProps.value || null });
    }
  }

  componentDidUpdate(prevProps) {
    const { formInitial } = this.props;
    if (prevProps.formInitial.uid !== formInitial.uid) {
      this.sideEffects();
    }
  }

  componentWillUnmount() {
    const { releaseForm, name } = this.props;
    this.preventSideEffects();
    releaseForm(name);
  }

  isRequired() {
    const { field } = this.props;
    return field.required || false;
  }

  onChangeInputSuggest = label => {
    const { data } = this.state;
    const userData = data.find(({ label: userLabel }) => userLabel === label);
    this.onChange((userData && userData.value) || '');
  };

  onChange = value => {
    const { candidatesData } = this.state;
    const {
      blockForm,
      releaseForm,
      name,
      formInitial: initial,
      dispatch,
    } = this.props;
    let action = null;
    if (value && value !== initial[name]) {
      if (candidatesData.findIndex(c => c.uid === value) !== -1) {
        action = 'accept';
      } else {
        action = 'invite';
      }
    }
    if (this.isRequired()) {
      if (value) {
        releaseForm(name);
      } else {
        blockForm(name);
      }
    }
    dispatch(change('inscriptionForm', name, value));
    dispatch(change('inscriptionForm', `${name}Action`, action));
  };

  setChoseAction = choseAction => {
    const { blockForm, releaseForm, name } = this.props;
    if (choseAction === 'no') {
      this.backToCandidates();
      if (this.isRequired()) {
        releaseForm(name);
      }
    } else {
      const { forcedValue } = this.state;
      if (!forcedValue && this.isRequired()) {
        blockForm(name);
      }
    }
    this.setState({ choseAction });
  };

  hasRoom() {
    const { room } = this.props;
    return _.isObject(room) && _.size(room) > 0;
  }

  forceValue = forcedValue => {
    this.setState({ forcedValue });
    this.onChange(forcedValue);
  };

  forceValueEmpty = () => {
    this.forceValue('');
  };

  backToCandidates = () => {
    this.forceValue(null);
  };

  hasCandidates() {
    const { candidatesData } = this.state;
    const { value } = this.props;
    return !_.isEmpty(candidatesData.filter(c => c.uid !== value));
  }

  // should probably be an action...
  sideEffects(props = this.props) {
    const { executeQuery, field, roles } = this.props;
    let choseAction = !_.isEmpty(props.value) ? 'yes' : null;
    this.setState({ loading: true });

    const dataPromise = executeQuery(props.field.dataQuery); // props? connect to the action directly ?
    const candidatesPromise = executeQuery(props.field.candidatesQuery, {
      logged: true,
    });

    Promise.all([dataPromise, candidatesPromise]).then(
      ([dataRes, candidatesRes]) => {
        if (this.isUnmounted) {
          return;
        }
        let data = [];
        let candidatesData = [];
        if (_.get(dataRes, `body.data.${props.field.dataQuery.queryName}`)) {
          data = dataRes.body.data[field.dataQuery.queryName].filter(
            d => d.force || _.isEmpty(d.role) || _.includes(roles, d.role),
          );
        }
        if (
          _.get(
            candidatesRes,
            `body.data.${props.field.candidatesQuery.queryName}`,
          )
        ) {
          candidatesData =
            candidatesRes.body.data[props.field.candidatesQuery.queryName];
          // Bring back into data all the user candidates
          _.forEach(candidatesData, candidate => {
            let dataCandidate = data.find(d => d.value === candidate.uid);
            if (!dataCandidate) {
              dataCandidate = { value: candidate.uid };
              data.push(dataCandidate);
            }
            dataCandidate.label = candidate.label;
            dataCandidate.force = true;
          });
        }
        if (!_.isEmpty(candidatesData) && _.isNull(choseAction)) {
          choseAction = 'yes';
        }
        this.setState({ loading: false, data, candidatesData, choseAction });
      },
    );
  }

  preventSideEffects = () => {
    // this.isMounted() is not to be used and we cannot cancel promises so...
    // potentially see makeCancelable pattern https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    this.isUnmounted = true;
  };

  render() {
    const { name, disabled, value } = this.props;
    const {
      data,
      candidatesData,
      loading,
      forcedValue,
      choseAction,
    } = this.state;

    const hasRoom = this.hasRoom();
    const contact = (
      <a className="resa-primary" href="mailto:palatine2018@phileog.com">
        CONTACT
      </a>
    );

    const forcedUser = data.find(
      ({ value: userValue }) => forcedValue === userValue,
    );
    const forcedLabel = (forcedUser && forcedUser.label) || '';

    const twinSelection =
      _.isNull(forcedValue) && this.hasCandidates() ? (
        <Candidates
          name={name}
          candidatesData={candidatesData}
          selectCandidates={this.forceValue}
          selectNone={this.forceValueEmpty}
        />
      ) : (
        <div>
          {(value && (
            <FormattedMessage
              id="twins.select.before"
              defaultMessage="Your room mate is below, in case of problem: {contact}"
              values={{ contact }}
              tagName="div"
            />
          )) || (
            <FormattedMessage
              id="twins.select.after"
              defaultMessage={`Type the first letters of your room mate, then choose from the list.{br}
                In case of problem: {contact}`}
              values={{
                br: <br />,
                contact,
              }}
              tagName="div"
            />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row wrap',
              width: 'calc(100% + 10px)',
              margin: '-5px',
            }}
          >
            <div style={{ flex: '1 0 250px', margin: '5px' }}>
              <InputSuggest
                list={data}
                placeholder="Recherchez votre binône..."
                onChange={this.onChangeInputSuggest}
                initialLabel={forcedLabel}
                inputProps={{
                  disabled: disabled || loading || hasRoom,
                }}
              />
            </div>
            {this.hasCandidates() && !hasRoom && (
              <Tooltip title="Retour à la liste de demandes">
                <IconButton onClick={this.backToCandidates}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      );

    return (
      <div>
        {loading ? (
          <FormattedMessage
            id="twins.loading"
            defaultMessage="Twins are loading..."
          />
        ) : (
          <div>
            {!hasRoom && (
              <TwinToggle
                name={name}
                onChange={this.setChoseAction}
                actionValue={choseAction}
                required={this.isRequired()}
              />
            )}
            {_.isNil(choseAction) && ''}
            {choseAction === 'no' && (
              <FormattedMessage
                id="twins.no"
                defaultMessage="A roommate will be automatically assigned later."
              />
            )}
            {choseAction === 'yes' && twinSelection}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formInitial: getFormInitialValues('inscriptionForm')(state),
});

export default connect(mapStateToProps)(Twin);
