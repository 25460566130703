import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getFormValues,
  getFormInitialValues,
  reduxForm,
  Form,
  registerField,
  change,
} from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import Button from '@material-ui/core/Button';

import { blockForm, releaseForm } from '../../redux/summonForm/actionCreators';
import { getMetricsByRole, getQuotaReached } from '../../selectors/metrics';

import { summonForm as summonFormMessages } from '../../i18n/dynamic';
import SummonFields from './SummonFields';

import isConditionFulfilled from './conditions';

export const JSON_STRING = '##JSON_STRING##';

/** @extends {Component<import('react-redux').ConnectedProps<typeof connector> & import('redux-form').ConfigProps>} */
class SummonForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired, // Actual form configuration
    formStructure: PropTypes.shape().isRequired, // Actual form configuration
    withSubmit: PropTypes.bool, // Submit can be handled out of the form, if so, no submit button
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    canSubmit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired, // [redux-form] Submit callback
    isLoading: PropTypes.bool,
    pristine: PropTypes.bool, // [redux-form] Form have not been modified (can have value if initialized)
    invalid: PropTypes.bool, // [redux-form] Form has invalid values (see validators)
    submitting: PropTypes.bool, // [redux-form] Form desactivated and in loading state
    roles: PropTypes.arrayOf(PropTypes.string),
    metrics: PropTypes.objectOf(
      PropTypes.shape({
        value: PropTypes.number,
        quota: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    quotaReached: PropTypes.shape().isRequired,
    intl: intlShape.isRequired,
    // @see SummonFields props
  };

  static defaultProps = {
    withSubmit: true,
    submitLabel: '',
    canSubmit: true,
    isLoading: false,
    pristine: true,
    invalid: false,
    submitting: false,
    roles: [],
    metrics: {},
  };

  constructor(props) {
    super(props);
    this.initializeConditions();
  }

  shouldComponentUpdate(nextProps) {
    const { isLoading } = this.props;
    // Do not re-render during loading time
    return !(isLoading && nextProps.isLoading);
  }

  /**
   * Function to extract from a form structure the conditions of its fields
   * Sets this.conditions, in the form:
   *   { field1: [ condition, ... ], field2: [ condition, ... ], ... }
   * @param  {object}  structure           Structure from which extract the conditions
   * @param  {array}   inheritedConditions Conditions that comes from parents (recursive calls)
   * @author Sylvain Pont
   */
  initializeConditions(structure, inheritedConditions = []) {
    if (!this.conditions) this.conditions = {}; // Initialize this.conditions
    if (!structure) {
      // When called for the first time
      const { formStructure: rootStructure } = this.props;
      if (rootStructure.fields) {
        // Form with only fields
        this.initializeConditions(rootStructure, inheritedConditions);
      } else {
        // Form with multiple cards or steps (they can have conditions of their own)
        const multiStructure = rootStructure.cards || rootStructure.steps;
        _.forEach(multiStructure, (subStructure) => {
          const condInherit = [...inheritedConditions];
          if (subStructure.condition) condInherit.push(subStructure.condition);
          this.initializeConditions(subStructure, condInherit);
        });
      }
    } else {
      _.forEach(structure.fields, (field) => {
        const fieldConditions = [...inheritedConditions];
        if (field.condition) fieldConditions.push(field.condition);
        if (fieldConditions.length)
          this.conditions[field.name] = fieldConditions;
      });
    }
  }

  /**
   * On form submit look for fields that are not displayed due to conditions and remove their values, so the backend can apply default values
   * @param  {object} values Values from redux-form
   * @return {bool}          Result of sync validation for redux-form
   * @author Sylvain Pont
   */
  handleFormSubmit = (rawValues) => {
    let values = _.clone(rawValues);
    const { roles, metrics, onSubmit } = this.props;

    // Remove fields that are hidden due to conditions
    _.forEach(this.conditions, (fieldConditions, fieldName) => {
      // ^ Check for all fields that have conditions
      if (values[fieldName]) {
        let fieldConditionsFulfilled = true;
        _.forEach(fieldConditions, (condition) => {
          // ^ Check for all conditions to be fulfilled
          fieldConditionsFulfilled =
            fieldConditionsFulfilled &&
            isConditionFulfilled(condition, rawValues, { roles, metrics });
        });
        if (!fieldConditionsFulfilled) {
          // Remove the field from values if all conditions are not fulfilled
          values = _.omit(values, fieldName);
        }
      }
    });

    // Remove null values (so default can be applied by backend)
    values = _.omitBy(values, _.isNil);

    let valuesFromTemplates = {};
    values = _.forEach(values, (value, key) => {
      if (_.isString(value) && value.startsWith(JSON_STRING)) {
        delete values[key];
        const subValues = JSON.parse(value.substr(JSON_STRING.length));
        valuesFromTemplates = { ...valuesFromTemplates, ...subValues };
      }
    });
    values = { ...valuesFromTemplates, ...values };

    return onSubmit(values);
  };

  registerField = (fieldName) => {
    const { dispatch, formName } = this.props;
    return dispatch(registerField(formName, fieldName, 'Field'));
  };

  changeFieldValue = (fieldName, fieldNewValue) => {
    const { dispatch, formName } = this.props;
    return dispatch(change(formName, fieldName, fieldNewValue));
  };

  blockForm = (name) => {
    const { dispatch } = this.props;
    dispatch(blockForm(name));
  };

  releaseForm = (name) => {
    const { dispatch } = this.props;
    dispatch(releaseForm(name));
  };

  render() {
    const {
      withSubmit,
      submitLabel,
      canSubmit,
      onSubmit,
      handleSubmit,
      intl,
      ...fieldsProps
    } = this.props;
    const {
      formStructure: { steps },
      // pristine,
      invalid,
      submitting,
    } = fieldsProps;
    const dispatchers = {
      registerField: this.registerField,
      changeFieldValue: this.changeFieldValue,
      blockForm: this.blockForm,
      releaseForm: this.releaseForm,
    };
    const showFormSubmit = withSubmit && !steps;

    return (
      <div>
        {/* 1) handleSubmit (redux-form) is called
            2) if form is valid this.handleFormSubmit will be called
            3) which will call props.onSubmit */}
        <Form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <SummonFields {...fieldsProps} {...dispatchers} />
          {showFormSubmit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 10px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={/* pristine || */ invalid || submitting || !canSubmit}
              >
                {submitLabel || intl.formatMessage(summonFormMessages.submit)}
              </Button>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

/** @param {State} state */
const mapStateToProps = (state, props) => {
  const { form, profile } = state;
  return {
    // reduxForm config
    form: props.formName,
    // TODO Move this only where it's needed
    formValues: getFormValues(props.formName)({ form }),
    formInitialValues: getFormInitialValues(props.formName)({ form }),
    destroyOnUnmount: false, // d: true
    // forceUnregisterOnUnmount: false, // d: false // Unregister fields as they unmount (for things like stepper)
    // enableReinitialize: true, // d: false // Reset values if initialValues change
    // keepDirtyOnReinitialize: true, // d: false // Keep dirty fields in canse of [see enableReinitialize]
    updateUnregisteredFields: true, // should be d: true // on reinitialize (if enabled anyway) update all fields, not only registered ones
    roles: profile.roles,
    metrics: getMetricsByRole(state),
    quotaReached: getQuotaReached(state),
  };
};

const connector = connect(mapStateToProps);

export default compose(connector, reduxForm({}), injectIntl)(SummonForm);
