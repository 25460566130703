import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import _ from 'lodash';

import { getFormValues, change } from 'redux-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SummonForm from '../../lib/SummonForm/SummonForm';
import Login from '../Login';
import RegistrationOk from './RegistrationOk';
import RegistrationNOk from './RegistrationNOk';

import {
  handleSubmit,
  setSelectedEvents,
  uploadFile,
  executeQuery,
} from '../../redux/root/actionCreators';

import CONFIG from '../../config/config.yaml';
import FORMS from '../../config/forms.yaml';
import GRAPHQL from '../../config/graphql.yaml';
import { application as applicationMessages } from '../../i18n/dynamic';

/** @extends {React.Component<import('react-redux').ConnectedProps<typeof connector> & {}>} */
class MainForm extends React.Component {
  static propTypes = {
    // React-Intl
    intl: intlShape.isRequired,
    // Redux
    dispatch: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    eventList: PropTypes.arrayOf(PropTypes.shape()),
    finished: PropTypes.bool.isRequired,
    showForm: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      room: PropTypes.shape(),
      roles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    formValues: PropTypes.shape({
      presence: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
    // Router
    match: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static defaultProps = {
    eventList: [],
    formValues: { presence: false },
  };

  // Build them only once or each rendering will be "new" components (re-render)
  get customComponents() {
    const {
      user: { firstName = '', lastName = '' },
    } = this.props;
    const customComponentProps = { firstName, lastName };
    if (
      !this.customComponentsBuilt ||
      !_.isEqual(this.customComponentsBuiltProps, customComponentProps)
    ) {
      this.customComponentsBuiltProps = customComponentProps;
      this.customComponentsBuilt = {
        bonjour: () => (
          <div>
            Bonjour {firstName} {lastName}
          </div>
        ),
      };
    }
    return this.customComponentsBuilt;
  }

  setSelectedEvents = (added, removed) => {
    const { dispatch } = this.props;
    return dispatch(setSelectedEvents(added, removed));
  };

  executeQuery = (query, options = {}) => {
    const { dispatch } = this.props;
    return dispatch(executeQuery(query, options));
  };

  uploadFile = ({ meta, file }, progressCallback, abortCallbackSaver) => {
    const { dispatch } = this.props;
    return dispatch(
      uploadFile({ meta, file }, progressCallback, abortCallbackSaver),
    );
  };

  /** @type {import('redux-form').ConfigProps['onSubmit']} */
  handleSubmit = (rawValues) => {
    const { dispatch, intl } = this.props;
    return dispatch(handleSubmit(rawValues)).catch((error) => {
      // FIXME - User friendly error handling
      if (error.code === 'CredentialsError') {
        // eslint-disable-next-line no-alert
        alert(`${intl.formatMessage(applicationMessages.sessionExpired)}`);
      } else {
        // eslint-disable-next-line no-alert
        alert(`${intl.formatMessage(applicationMessages.errorOccured)}`);
      }
      window.location.reload();
    });
  };

  /** @type {import('redux-form').ConfigProps['validate']} */
  validate = (values) => {
    const errors = {};
    if (!values.presence) return {};
    return errors;
  };

  /**
   * Custom AXA 2023, double plenary
   * @type {import('redux-form').ConfigProps['onChange']}
   */
  handleChange = (values, dispatch, props, previousValues) => {
    const { selectPlenary = [] } = values;
    const { selectPlenary: prevSelectPlenary = [] } = previousValues;
    if (
      selectPlenary.length === 1 &&
      selectPlenary.length !== prevSelectPlenary.length
    ) {
      if (selectPlenary.length < prevSelectPlenary.length) {
        // disable all
        dispatch(change('inscriptionForm', 'selectPlenary', []));
      } else {
        // enable all
        dispatch(
          change('inscriptionForm', 'selectPlenary', [
            'plen_am',
            'plen_pm',
          ]),
        );
      }
    }
  };

  render() {
    const {
      user: { uid, room },
      eventList,
      isLoading,
      isMobile,
      showForm,
      finished,
      formValues,
      location,
      match: {
        params: { modify },
      },
    } = this.props;

    const renderLogin = () => (
      <Card>
        <CardContent>
          <Login
            providers={['resa']}
            magic={modify === 'magic'}
            location={location}
          />
        </CardContent>
      </Card>
    );

    const renderForm = () => (
      <SummonForm
        formName="inscriptionForm"
        formStructure={FORMS.inscription}
        calendarMappings={FORMS.calendarMappings}
        graphQlInfos={GRAPHQL}
        onSubmit={this.handleSubmit}
        withSubmit={false}
        eventList={eventList}
        isLoading={isLoading}
        isMobile={isMobile}
        uploadFile={this.uploadFile}
        uid={uid}
        filesBaseUrl={`${CONFIG.S3CloudFront}/resa/attachments/${CONFIG.resaKey}/${uid}`}
        assetsBaseUrl={`${CONFIG.S3CloudFront}/static/assets`}
        room={room}
        executeQuery={this.executeQuery}
        setSelectedEvents={this.setSelectedEvents}
        customComponents={this.customComponents}
        validate={this.validate}
        onChange={this.handleChange}
      />
    );

    const renderConfirmationOK = () => (
      <Card>
        <CardContent>
          <RegistrationOk />
        </CardContent>
      </Card>
    );

    const renderConfirmationNOK = () => (
      <Card>
        <CardContent>
          <RegistrationNOk />
        </CardContent>
      </Card>
    );

    let formState = null;

    if (isLoading) {
      // User is loading => show the form (as disabled)
      formState = renderForm();
    } else if (!uid) {
      // User is loaded but not logged in => authenticate
      formState = renderLogin();
    } else if (showForm || !CONFIG.hideAfterFinished) {
      // User is loaded and is logged in => show the form
      formState = renderForm();
    } else if (
      // Form finished means that presence field must be filled
      // If presence is undefined, it means that there is no presence fields, so consider it to be true
      finished &&
      (_.isUndefined(formValues.presence) || formValues.presence)
    ) {
      // User is loaded, logged in, but already finished => show confirmationOK
      formState = renderConfirmationOK();
    } else {
      // User is loaded, logged in, but said he didn't wanted to participate => show confirmationNOK
      formState = renderConfirmationNOK();
    }

    return formState;
  }
}

/** @param {State} state */
const mapStateToProps = (state) => {
  const { root, profile, summonForm } = state;
  return {
    isMobile: root.isMobile,
    isLoading: root.isLoading,
    eventList: root.eventList,
    finished: summonForm.finished,
    showForm: summonForm.showForm,
    user: {
      uid: profile.uid,
      firstName: profile.firstName,
      lastName: profile.lastName,
      room: profile.room,
    },
    formValues: getFormValues('inscriptionForm')(state),
  };
};

const connector = connect(mapStateToProps);

export default compose(withRouter, connector, injectIntl)(MainForm);
