import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';

import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import cx from 'classnames';
import { change } from 'redux-form';

import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import SummonField from './SummonField';

import { summonForm as summonFormMessages } from '../../../i18n/dynamic';
import I18n from '../../I18n';

import { requiredValidator } from '../validators';
import { JSON_STRING } from '../SummonForm';

/** @typedef {import('../../../types/config').SelectOption} SelectOption */

countries.registerLocale(countriesEn);
const locale = I18n.getLocale();

// https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
// TODO: better fix ??
// eslint-disable-next-line no-underscore-dangle
momentTZ.defineLocale(locale, moment.localeData()._config); // copy locale to moment-timezone
momentTZ.locale(locale);

const styles = (theme) => ({
  menu: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  menuList: {
    boxSizing: 'border-box',
  },
  menuItem: {
    display: 'flex',
    height: 'auto',
    minHeight: '24px',
    '&:nth-child(2n)': {
      backgroundColor: fade(theme.palette.secondary.light, 0.05),
    },
    '&:hover, &:focus': {
      backgroundColor: 'rgba(51, 51, 51, 0.1)',
    },
  },
  menuItemSelected: {
    backgroundColor: `${fade(theme.palette.secondary.light, 0.25)} !important`,
    '& $menuItemPrimaryTextTime': {
      color: theme.palette.secondary.main,
    },
  },
  menuItemContentWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  menuItemPrimaryTextWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItemPrimaryText: {
    color: theme.palette.primary.main,
    whiteSpace: 'normal',
  },
  menuItemPrimaryTextTime: {
    fontStyle: 'italic',
  },
  menuItemSecondaryText: {
    fontStyle: 'italic',
    marginLeft: '10px',
    color: 'rgba(51, 51, 51, 0.8)',
    whiteSpace: 'pre-line',
    lineHeight: '1.2em',
    display: 'inline-block',
  },
  menuItemSecondaryTextDisabled: {
    color: 'rgba(51, 51, 51, 0.3)',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  hiddenCheckbox: {
    opacity: 0,
  },
});

class Select extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    field: PropTypes.shape({
      label: PropTypes.string,
      labelStyle: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      options: PropTypes.oneOfType([
        PropTypes.shape({}), // Calendar
        PropTypes.arrayOf(PropTypes.shape({})), // Listed options
      ]),
      country: PropTypes.bool,
      showSummary: PropTypes.bool,
      required: PropTypes.bool,
      multiple: PropTypes.bool,
      nullable: PropTypes.bool,
      defaultValue: PropTypes.string,
      defaultIndex: PropTypes.number,
    }).isRequired,
    changeFieldValue: PropTypes.func,
    values: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string), // Muti select
      PropTypes.string, // Simple select
    ]),
    initialValues: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string), // Muti select
      PropTypes.string, // Simple select
    ]),
    isLoading: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    calendarMappings: PropTypes.shape({}),
    templateMappings: PropTypes.shape({}),
    eventList: PropTypes.arrayOf(PropTypes.shape({})),
    setSelectedEvents: PropTypes.func.isRequired,
    quotaReached: PropTypes.shape({}),
    roles: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    // Styles
    classes: PropTypes.object.isRequired,
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    calendarMappings: {},
    templateMappings: {},
    eventList: [],
    quotaReached: {},
    changeFieldValue: _.noop,
    values: [],
    initialValues: [],
    roles: [],
  };

  /** @type {SelectOption[] | null} */
  static countriesOptions = null;

  static getCountriesOptions() {
    if (_.isEmpty(Select.countriesOptions)) {
      Select.countriesOptions = _(countries.getNames('en'))
        .map((country, countryCode) => ({
          label: country,
          value: countryCode,
        }))
        .orderBy('label')
        .value();
    }
    return Select.countriesOptions;
  }

  constructor(props) {
    super(props);
    this.setOptions();
    if (!props.isLoading) {
      this.init();
    }
    this.closeRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { isMobile, disabled, values, quotaReached, eventList } = this.props;
    return (
      isMobile !== nextProps.isMobile ||
      disabled !== nextProps.disabled ||
      values !== nextProps.values ||
      quotaReached !== nextProps.quotaReached ||
      (this.isEvents() && !_.isEqual(eventList, nextProps.eventList))
    );
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    if (prevProps.isLoading && !isLoading) {
      this.init();
    }
  }

  init() {
    const { name, changeFieldValue, field, values, user } = this.props;
    const { defaultValue, defaultIndex } = field;
    const hasValue = !_.isNil(values) && !_.isEmpty(values);
    if (!hasValue) {
      let value;
      const mm = (user.uid || '').match(/(.*)-adm$/);
      if (defaultValue) {
        value = defaultValue;
      } else if (
        name === 'owner' &&
        mm &&
        this.options &&
        this.options.some(({ value: optVal }) => optVal === mm[1])
      ) {
        // SPECIAL AXA 2023 Owner select
        ({ 1: value } = mm);
      } else if (
        _.isInteger(defaultIndex) &&
        this.options &&
        !_.isNil(this.options[defaultIndex])
      ) {
        const { value: defaultOptionValue } = this.options[defaultIndex];
        value = defaultOptionValue;
      }
      if (!_.isNil(value)) {
        changeFieldValue(name, value);
      }
    }
  }

  handleCloseRef = (ref) => {
    this.closeRef.current = ref;
  };

  /**
   * This is Redux Form Field https://redux-form.com/7.4.2/docs/api/field.md/
   * @param {Event} event
   * @param {string | string[]} selectedValues
   */
  onChange = (event, selectedValues, previouslySelectedValues) => {
    if (
      this.isEvents() &&
      !_.isEqual(
        _.castArray(selectedValues),
        previouslySelectedValues && _.castArray(previouslySelectedValues),
      )
    ) {
      const {
        setSelectedEvents,
        field: { multiple },
        dispatch,
        name,
        formName,
      } = this.props;
      let addedSelectedValues = [];
      let removedSelectedValues = [];
      if (multiple) {
        if (
          !selectedValues ||
          (Array.isArray(selectedValues) && selectedValues.includes(''))
        ) {
          // 2023 - unselect all if None selected
          event.preventDefault(); // prevents default change action !
          dispatch(change(formName, name, []));
          // close through parent ref
          if (this.closeRef.current) this.closeRef.current();
          return;
        }
        addedSelectedValues = _.difference(
          selectedValues,
          previouslySelectedValues,
        );
        removedSelectedValues = _.difference(
          previouslySelectedValues,
          selectedValues,
        );
      } else if (selectedValues !== previouslySelectedValues) {
        addedSelectedValues = !_.isEmpty(selectedValues)
          ? _.castArray(selectedValues)
          : [];
        removedSelectedValues = !_.isEmpty(previouslySelectedValues)
          ? _.castArray(previouslySelectedValues)
          : [];
      }
      if (
        !_.isEmpty(addedSelectedValues) ||
        !_.isEmpty(removedSelectedValues)
      ) {
        setSelectedEvents(addedSelectedValues, removedSelectedValues);
      }
    }
  };

  renderMultipleValues = (values) => {
    const {
      field: { label, placeholder },
      intl: { formatMessage },
    } = this.props;
    const nbValues = values.length;
    if (nbValues > 0) {
      const { event, events } = summonFormMessages;
      const title = label || placeholder;
      const eventMessage =
        nbValues === 1 ? formatMessage(event) : formatMessage(events);
      const axa = true;
      if (axa) return `${nbValues} item(s)`; // AXA Special
      return `${title} (${nbValues} ${eventMessage})`;
    }
    return 'None';
  };

  setOptions() {
    const {
      field: { options },
      calendarMappings,
      templateMappings,
      name,
      initialValues,
      eventList,
      quotaReached,
      roles,
    } = this.props;
    /** @type {SelectOption[] | null} */
    let selectOptions = [];
    if (this.isEvents()) {
      // Select all the events that pass the needed test
      //  Ex: if options.calendar = {key: 'folder', value: 'a'}
      //    => Take only the events that have event.folder === a
      selectOptions = _.filter(eventList, (event) => {
        const eventRoles = Array.isArray(event.roles)
          ? event.roles
          : [event.roles];
        return (
          event[calendarMappings[name].key] === calendarMappings[name].value &&
          (_.isEmpty(event.roles) || // No role associated to the events
            _.some(roles, (role) => eventRoles.includes(role)))
        ); // Matching roles
      });
    } else if (this.isTemplate()) {
      selectOptions = templateMappings[name].map((option) => ({
        ...option,
        value: `${JSON_STRING}${JSON.stringify(option.value)}`,
      }));
    } else if (this.isCountry()) {
      selectOptions = Select.getCountriesOptions();
    } else {
      selectOptions = options;
    }
    _.forEach(selectOptions, (o) => {
      if (o.quota) {
        const hadInitialValue =
          _.isArray(initialValues) &&
          !_.isUndefined(initialValues.find((value) => value === o.value));
        o.quotaDisabled =
          !hadInitialValue &&
          (!!quotaReached[o.quota] || quotaReached[o.quota] === undefined); // disable until we have metrics
      }
    });

    this.options = selectOptions;
  }

  hasDefinedOptions() {
    const { field } = this.props;
    return !_.isNil(field.options);
  }

  isCountry() {
    const { field } = this.props;
    return field.country === true;
  }

  isEvents() {
    const { calendarMappings, name } = this.props;
    return (
      !this.hasDefinedOptions() &&
      !this.isCountry() &&
      !_.isNil(calendarMappings[name])
    );
  }

  isTemplate() {
    const { templateMappings, name } = this.props;
    return (
      !this.hasDefinedOptions() &&
      !this.isCountry() &&
      !_.isNil(templateMappings[name])
    );
  }

  hasDynamicOptions() {
    return this.isEvents();
  }

  isOptionChecked(optionValue) {
    const { values } = this.props;
    return !!values && values.indexOf(optionValue) > -1;
  }

  displaySchedule = (start, end) => {
    const { isMobile } = this.props;
    const format = isMobile ? 'ddd[.] h:mm a' : 'dddd h:mm a';
    const startTime = momentTZ.tz(start, 'Europe/Paris').format(format);
    let display = '';
    display = _.upperFirst(startTime);
    if (end) {
      const endTime = momentTZ.tz(end, 'Europe/Paris').format('h:mm a');
      display += ` - ${_.upperFirst(endTime)}`;
    }
    return display;
  };

  render() {
    const { isMobile, name, disabled, values, field, classes } = this.props;
    const {
      label,
      labelStyle = {},
      placeholder,
      options: originalOptions,
      required,
      multiple,
      nullable,
      defaultIndex,
      defaultValue,
      showSummary,
      country,
      ...onlyInput
    } = field;
    const input = { ...onlyInput, multiple };
    const validators = [];
    if (required) validators.push(requiredValidator);
    if (this.hasDynamicOptions()) {
      this.setOptions();
    }
    if (!this.options || _.isEmpty(this.options)) return <div />;

    const hasHeaders = this.options.findIndex((option) => option.header) > -1;
    const isTemplate = this.isTemplate();

    const emptyLabel = multiple ? 'None' : 'Change';

    return (
      <div>
        <SummonField
          key={`summonField-${name}`}
          reduxFormField={{
            name,
            validate: [...validators],
            format: multiple
              ? (value) => value
              : (value) => (!_.isArray(value) ? value : value[0] || null),
            normalize: (value) => (_.isEmpty(value) ? '' : value), // https://github.com/erikras/redux-form/issues/3113
            onChange: this.onChange,
          }}
          component="select"
          componentProps={{
            /** bad bad bad */
            getCloseRef: this.handleCloseRef,
            label: label && (
              <span style={labelStyle}>
                {label}
                {required && (
                  <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
                )}
              </span>
            ),
            renderValue: multiple ? this.renderMultipleValues : null,
            disabled,
            fullWidth: true,
            margin: 'normal',
            MenuProps: {
              disableAutoFocusItem: true,
              MenuListProps: {
                dense: true,
                disablePadding: true,
                classes: { root: classes.menuList },
              },
              PopoverClasses: {
                paper: classes.menu,
              },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            },
            children: (nullable
              ? [
                  <MenuItem key={`${name}-option-empty`} value='' divider>
                    {multiple || (values && values.length) ? (
                      <i>- {emptyLabel} -</i>
                    ) : (
                      ''
                    )}
                  </MenuItem>,
                ]
              : []
            ).concat(
              _.map(this.options, (option, index) => {
                const oIsChecked = this.isOptionChecked(option.value);
                const oIsDisabled =
                  !oIsChecked &&
                  (option.unavailable || option.quotaDisabled || option.header);
                const oIsInset =
                  (!isMobile && multiple) || (hasHeaders && !option.header);
                const oShowCheckbox = !isMobile && multiple;

                return (
                  <MenuItem
                    key={`${name}-option-${option.value}`}
                    value={option.value}
                    disabled={oIsDisabled}
                    classes={{
                      root: classes.menuItem,
                      selected: classes.menuItemSelected,
                    }}
                    divider={Boolean(
                      this.options && index <= this.options.length - 1,
                    )}
                  >
                    <div>
                      {oIsInset && (
                        <Checkbox
                          checked={oIsChecked}
                          classes={{
                            root: cx(classes.checkbox, {
                              [classes.hiddenCheckbox]: !oShowCheckbox,
                            }),
                          }}
                          disabled={oIsDisabled}
                          disableRipple
                        />
                      )}
                    </div>
                    <div className={classes.menuItemContentWrapper}>
                      <div className={classes.menuItemPrimaryTextWrapper}>
                        <span className={classes.menuItemPrimaryText}>
                          {option.label}
                        </span>
                        {option.start && (
                          <span className={classes.menuItemPrimaryTextTime}>
                            {this.displaySchedule(option.start, option.stop)}
                          </span>
                        )}
                      </div>
                      {(option.description || isTemplate) && !isMobile && (
                        <div
                          className={cx(classes.menuItemSecondaryText, {
                            [classes.menuItemSecondaryTextDisabled]:
                              oIsDisabled,
                          })}
                        >
                          {option.description
                            ? option.description
                            : `${option.value.substr(JSON_STRING.length)}`}
                        </div>
                      )}
                    </div>
                  </MenuItem>
                );
              }),
            ),
            ...input,
          }}
        />
        {showSummary &&
          values &&
          _(values)
            .castArray()
            .map((selectValue) =>
              _.find(this.options, (option) => option.value === selectValue),
            )
            .filter(
              /** @returns {event is SelectOption} */ (event) =>
                !!event && !_.isUndefined(event),
            )
            .sortBy((event) => moment(event.start).valueOf())
            .map((event) => (
              <div key={`recap-${event.value}`}>
                {`${event.label}${
                  event.start
                    ? ` - ${this.displaySchedule(
                        event.start /* , event.stop */,
                      )}`
                    : ''
                }`}
              </div>
            ))
            .value()}
      </div>
    );
  }
}

/** @param {State} state */
const mapStateToProps = ({ profile }) => ({
  user: {
    uid: profile.uid,
    firstName: profile.firstName,
    lastName: profile.lastName,
  },
});

export default _.flowRight(
  connect(mapStateToProps),
  injectIntl,
  withStyles(styles),
)(Select);
