import React from 'react';
import PropTypes from 'prop-types';

const Html = props => {
  const { html, style } = props;
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
      style={style}
    />
  );
};

Html.prototype.propTypes = {
  html: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

Html.defaultProps = {
  style: null,
};

export default Html;
