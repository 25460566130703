// https://goo.gl/6KgkJg

const ATTRIBUTES = [
  'additional-name',
  'address-level1',
  'address-level2', // aka city
  'address-level3',
  'address-level4',
  'address-line1',
  'address-line2',
  'address-line3',
  'bday-day',
  'bday-month',
  'bday-year',
  'bday',
  'cc-additional-name',
  'cc-csc',
  'cc-exp-month',
  'cc-exp-year',
  'cc-exp',
  'cc-family-name',
  'cc-given-name',
  'cc-name',
  'cc-number',
  'cc-type',
  'country-name',
  'country',
  'current-password',
  'email', // [home|work|mobile|pager|fax]
  'family-name',
  'given-name',
  'honorific-prefix',
  'honorific-suffix',
  'impp', // [home|work|mobile|pager|fax]
  'language',
  'name',
  'new-password',
  'nickname',
  'organization-title',
  'organization',
  'photo',
  'postal-code',
  'sex',
  'street-address',
  'tel-area-code', // [home|work|mobile|pager|fax]
  'tel-country-code', // [home|work|mobile|pager|fax]
  'tel-extension', // [home|work|mobile|pager|fax]
  'tel-local-prefix', // [home|work|mobile|pager|fax]
  'tel-local-suffix', // [home|work|mobile|pager|fax]
  'tel-local', // [home|work|mobile|pager|fax]
  'tel-national', // [home|work|mobile|pager|fax]
  'tel', // [home|work|mobile|pager|fax]
  'transaction-amount',
  'transaction-currency',
  'url',
  'username',
];

const ALIAS = {
  city: 'address-level2',
  firstName: 'given-name',
  lastName: 'family-name',
  mail: 'email',
  phone: 'tel',
  // special axa
  arrTel: 'tel',
  depTel: 'tel',
};

const kebab = string =>
  string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export default name => {
  // "[section-xxxx] [shipping|billing] [home|work|mobile|pager|fax] attribute "
  const attr = ALIAS[name] || kebab(name);
  if (ATTRIBUTES.includes(attr)) return attr;
  return 'off';
};
