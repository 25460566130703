import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { application as applicationMessages } from '../i18n/dynamic';

const SiteNotOpened = ({ intl }) => (
  <Card>
    <CardContent>
      <span
        style={{
          fontSize: '1.5em',
          fontWeight: 'bold',
          display: 'inline-block',
          marginBottom: '10px',
        }}
      >
        {intl.formatMessage(applicationMessages.siteNotOpened)}
      </span>
      <br />
      <span
        style={{
          fontStyle: 'italic',
          display: 'inline-block',
          marginLeft: '10px',
        }}
      >
        &ndash; {intl.formatMessage(applicationMessages.theTeam)}
      </span>
    </CardContent>
  </Card>
);

SiteNotOpened.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SiteNotOpened);
