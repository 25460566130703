export const SET_IS_MOBILE = 'root/SET_IS_MOBILE';
export const SET_IS_LOADING = 'root/SET_IS_LOADING';
export const TOGGLE_LEFT_DRAWER = 'root/TOGGLE_LEFT_DRAWER';
export const SET_LANDING_ACTIVE = 'root/SET_LANDING_ACTIVE';
export const SET_LANDING_ACTIVE_TAB = 'root/SET_LANDING_ACTIVE_TAB';
export const SET_CALENDAR = 'root/SET_CALENDAR';
export const SET_SELECTED_EVENTS = 'root/SET_SELECTED_EVENTS';
export const REMOVE_SELECTED_EVENTS = 'root/REMOVE_SELECTED_EVENTS';
export const SET_METRICS = 'root/SET_METRICS';
export const DISABLE_SWIPE = 'root/DISABLE_SWIPE';
export const ENABLE_SWIPE = 'root/ENABLE_SWIPE';
