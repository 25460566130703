import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { Field as ReduxFormField } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DatePicker from 'material-ui-pickers/DatePicker/DatePickerModal'; // do not import root because of typescript import error
import TimePicker from 'material-ui-pickers/TimePicker/TimePickerModal';

import InputSuggest from './AutoComplete/InputSuggest';
import autofill from '../autofill';

const renderFieldPropTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRerequired,
    onChange: PropTypes.func.isRerequired,
    onDragStart: PropTypes.func.isRerequired,
    onDrop: PropTypes.func.isRerequired,
    onFocus: PropTypes.func.isRerequired,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    autofilled: PropTypes.bool.isRequired,
    asyncValidating: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    form: PropTypes.string.isRequired, // form name
    initial: PropTypes.any,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    visited: PropTypes.bool.isRequired,
    warning: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
};

/**
 *  Render a MUI TextField using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderTextField = props => {
  const { input, meta, componentProps } = props;
  const { error, touched } = meta;

  return (
    <TextField
      error={touched && !_.isNil(error)}
      helperText={touched && error}
      margin="normal"
      {...input}
      {...componentProps}
    />
  );
};
renderTextField.propTypes = renderFieldPropTypes;

/**
 *  Render a TextField of type date using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderDateField = props => {
  const { input, meta, componentProps } = props;
  const { value, onFocus, onBlur, ...inputRest } = input;
  const { openToIfEmpty, ...componentPropsRest } = componentProps;
  const { error, touched } = meta;

  let openTo = 'day';
  if (!value && !_.isNil(openToIfEmpty)) {
    openTo = openToIfEmpty;
  }

  const momentLocaleData = moment.localeData();

  return (
    <DatePicker
      value={value || null}
      openTo={openTo}
      {...inputRest}
      {...componentPropsRest}
      onOpen={onFocus}
      onClose={onBlur}
      invalidDateMessage={<React.Fragment />} // No DatePicker's error message
      invalidLabel={momentLocaleData.invalidDate()}
      emptyLabel=""
      format={momentLocaleData.longDateFormat('L')}
      // Props passed to MUI's TextField
      margin="normal"
      error={touched && !_.isNil(error)}
      helperText={touched && error}
    />
  );
};
renderDateField.propTypes = renderFieldPropTypes;

/**
 *  Render a TextField of type time using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderTimeField = props => {
  const { input, meta, componentProps } = props;
  const { value, onFocus, onBlur, ...inputRest } = input;
  const { error, touched } = meta;

  const momentLocaleData = moment.localeData();
  const format = momentLocaleData.longDateFormat('LT');
  const ampm = format.includes('A');

  return (
    <TimePicker
      value={value || null}
      {...inputRest}
      {...componentProps}
      onOpen={onFocus}
      onClose={onBlur}
      invalidDateMessage={<React.Fragment />} // No DatePicker's error message
      invalidLabel={momentLocaleData.invalidDate()}
      emptyLabel=""
      format={format}
      ampm={ampm}
      minutesStep={5}
      // Props passed to MUI's TextField
      margin="normal"
      error={touched && !_.isNil(error)}
      helperText={touched && error}
    />
  );
};
renderTimeField.propTypes = renderFieldPropTypes;

/**
 *  Render a MUI TextField using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderCheckboxField = props => {
  const {
    input,
    //  meta,
    componentProps,
  } = props;
  const { value, ...inputRest } = input;
  const { isInt, label, ...componentPropsRest } = componentProps; // FIXME isInt ?

  return (
    <FormControlLabel
      control={<Checkbox checked={Boolean(value)} {...inputRest} />}
      label={label}
      {...componentPropsRest}
    />
  );
};
renderCheckboxField.propTypes = renderFieldPropTypes;

/**
 *  Render a RadioField using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderRadioField = props => {
  const { input, meta, componentProps } = props;
  const { error, touched } = meta;
  const {
    label,
    options,
    isInt,
    rowLabel,
    classes = {},
    ...componentPropsRest
  } = componentProps;

  const radio = (
    <RadioGroup
      {...input}
      {...componentPropsRest}
      classes={_.pickBy(classes, (v, k) => k !== 'formControlLabel')}
    >
      {options.map(option => (
        <FormControlLabel
          key={`${input.name}-${option.value}`}
          value={`${option.value}`}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );

  return (
    <FormControl error={error && touched} margin="normal" fullWidth>
      {rowLabel ? (
        <FormControlLabel
          control={radio}
          label={label}
          labelPlacement="start"
          classes={classes.formControlLabel}
        />
      ) : (
        <>
          <FormLabel>{label}</FormLabel>
          {radio}
        </>
      )}

      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
renderRadioField.propTypes = renderFieldPropTypes;

/**
 *  Render a Switch using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderToggleField = props => {
  const { input, meta, componentProps } = props;
  const { value, ...inputRest } = input;
  const { error, touched } = meta;
  const { label, ...componentPropsRest } = componentProps;

  return (
    <FormControl error={error && touched} fullWidth>
      <FormControlLabel
        control={<Switch checked={!!value} {...inputRest} />}
        label={label}
        labelPlacement="start"
        {...componentPropsRest}
      />
      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
renderToggleField.propTypes = renderFieldPropTypes;

/**
 *  Render a Switch using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderSelectField = props => {
  const { input, meta, componentProps } = props;
  const { value, onBlur, ...inputRest } = input;
  const { error, touched } = meta;
  const {
    label,
    fullWidth,
    margin,
    children,
    getCloseRef,
    ...componentPropsRest
  } = componentProps;

  const { multiple } = componentProps;

  const [open, setOpen] = React.useState(false);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpen = React.useCallback(() => {
    if (getCloseRef) getCloseRef(handleClose)
    setOpen(true);
  }, []);

  return (
    <FormControl error={error && touched} fullWidth={fullWidth} margin={margin}>
      <InputLabel>{label}</InputLabel>
      <Select
        input={<Input />}
        {...inputRest}
        value={value || (multiple ? [] : '')}
        onBlur={() => onBlur(value)}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        {...componentPropsRest}
        displayEmpty={touched}
      >
        {children}
      </Select>
      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
renderSelectField.propTypes = renderFieldPropTypes;

/**
 *  Render an autocomplete field base on MUI TextField using redux-form props
 *  @param  {object} props
 *  @return {node}
 */
const renderAutoCompleteField = props => {
  const {
    input,
    // meta,
    componentProps,
  } = props;

  return (
    <InputSuggest {...input} {...componentProps} initialLabel={input.value} />
  );
};
renderAutoCompleteField.propTypes = renderFieldPropTypes;

const components = {
  text: renderTextField,
  date: renderDateField,
  time: renderTimeField,
  checkbox: renderCheckboxField,
  radio: renderRadioField,
  toggle: renderToggleField,
  select: renderSelectField,
  autocomplete: renderAutoCompleteField,
};

/**
 *  Render a field to be redux-form wrapped
 *  @param  {object} props
 *  @return node
 */
const SummonField = ({
  reduxFormField,
  component: componentType,
  componentProps,
}) => {
  const Component = components[componentType];

  return (
    <ReduxFormField
      {...reduxFormField}
      component={Component}
      componentProps={componentProps}
      autoComplete={autofill(reduxFormField.name)} // Browser autocomplete (name, etc.)
    />
  );
};

SummonField.propTypes = {
  reduxFormField: PropTypes.shape({
    name: PropTypes.string.isRequired,
    format: PropTypes.func,
    normalize: PropTypes.func,
    parse: PropTypes.func,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    warn: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    withRef: PropTypes.bool,
  }).isRequired,
  component: PropTypes.oneOf(_.keys(components)).isRequired,
  componentProps: PropTypes.object,
};

SummonField.defaultProps = {
  componentProps: {},
};

export default SummonField;
