import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CSSTransitionGroup } from 'react-transition-group';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Loader, { styles as loaderStyles } from '../Loader';

import Stepper from './Stepper';

import HtmlContent from './Fields/HtmlContent';
import MarkdownContent from './Fields/MarkdownContent';
import Text from './Fields/Text';
import Toggle from './Fields/Toggle';
import ToggleR from './Fields/ToggleRadio';
import Checkbox from './Fields/Checkbox';
import Radio from './Fields/Radio';
import Date from './Fields/Date';
import Time from './Fields/Time';
import AutoComplete from './Fields/AutoComplete';
import Twin from './Fields/Twin';
import Select from './Fields/Select';
import FileUpload from './Fields/FileUpload';

import isConditionFulfilled from './conditions';

class SummonFields extends Component {
  static propTypes = {
    // App
    isMobile: PropTypes.bool,
    graphQlInfos: PropTypes.shape(),
    // SummonForm dispatchers
    registerField: PropTypes.func,
    changeFieldValue: PropTypes.func,
    blockForm: PropTypes.func,
    releaseForm: PropTypes.func,
    // Form
    formName: PropTypes.string,
    customComponents: PropTypes.objectOf(PropTypes.func),
    formValues: PropTypes.shape(),
    formInitialValues: PropTypes.shape(),
    formStructure: PropTypes.shape().isRequired, // Only required prop
    submitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    embeded: PropTypes.bool,
    // General
    uid: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    metrics: PropTypes.objectOf(
      PropTypes.shape({
        value: PropTypes.number,
        quota: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    quotaReached: PropTypes.shape(),
    executeQuery: PropTypes.func,
    assetsBaseUrl: PropTypes.string,
    // Event
    setSelectedEvents: PropTypes.func,
    calendarMappings: PropTypes.shape(),
    templateMappings: PropTypes.shape(),
    eventList: PropTypes.arrayOf(PropTypes.shape()),
    // File
    filesBaseUrl: PropTypes.string,
    uploadFile: PropTypes.func,
    // Twin
    room: PropTypes.shape(),
  };

  static defaultProps = {
    // App
    isMobile: false,
    graphQlInfos: {},
    // SummonForm dispatchers
    registerField: _.noop,
    changeFieldValue: _.noop,
    blockForm: _.noop,
    releaseForm: _.noop,
    // Form
    formName: '',
    customComponents: {},
    formValues: {},
    formInitialValues: {},
    submitting: false,
    isLoading: false,
    embeded: false,
    // General
    uid: '',
    roles: [],
    metrics: {},
    quotaReached: {},
    executeQuery: _.noop,
    assetsBaseUrl: '',
    // Event
    setSelectedEvents: _.noop,
    calendarMappings: {},
    templateMappings: {},
    eventList: [],
    // File
    filesBaseUrl: '',
    uploadFile: _.noop,
    // Twin
    room: {},
  };

  constructor(props) {
    super(props);
    this.generateFieldsSpecialTypes();
  }

  generateFieldsSpecialTypes() {
    const { graphQlInfos } = this.props;
    const intFieldNames = _.get(graphQlInfos, 'schema.int', []);
    this.fieldsSpecialTypes = { intFieldNames };
  }

  renderField(rawField) {
    let fieldComp;
    // Extract the props not meant to be applied on the Field
    const {
      name,
      type,
      fields,
      steps,
      condition,
      quota,
      writeOnce,
      component: customComp,
      ...field
    } = rawField;
    const {
      formName,
      isMobile,
      registerField,
      changeFieldValue,
      blockForm,
      releaseForm,
      customComponents,
      formValues,
      formInitialValues,
      submitting,
      isLoading,
      uid,
      roles,
      quotaReached,
      executeQuery,
      assetsBaseUrl,
      setSelectedEvents,
      calendarMappings,
      templateMappings,
      eventList,
      filesBaseUrl,
      uploadFile,
      room,
    } = this.props;

    const fieldInitialValue = formInitialValues[name];
    const fieldHadInitialValue = !(
      _.isNil(fieldInitialValue) || _.isEmpty(fieldInitialValue)
    );

    // Quota of total nb of users that can set this field is reached ?
    // If this field has initialValue, then current user is part of the quota ones, let him
    const quotaDisabled = !!quotaReached[quota] && !fieldHadInitialValue;

    // Field specified to be filled once and for all
    const writeDisabled = writeOnce && fieldHadInitialValue;

    // Field is disabled:
    // - on loading (all fields)
    // - on submitting (all fields)
    // - on quota reached (this specific field)
    // - on writeDisabled (this specific field)
    const disabled = !!(
      isLoading ||
      submitting ||
      quotaDisabled ||
      writeDisabled
    );
    // https://github.com/erikras/redux-form/issues/2971
    // During load, let the required fields as so, no matter if disabled
    // or that will create bugs on fields validation, errors won't be raised initially
    if (disabled && !isLoading) {
      // ^ disabled cannot be required...
      field.required = false;
    }

    // We might know a field have a special type from graphql schema, use the info
    const { intFieldNames } = this.fieldsSpecialTypes;
    if (intFieldNames.includes(name)) {
      field.isInt = true;
    }

    switch (type) {
      case 'html':
        fieldComp = <HtmlContent name={name} field={field} />;
        break;
      case 'markdown': {
        fieldComp = <MarkdownContent name={name} field={field} />;
        break;
      }
      case 'text':
      case 'email':
      case 'textarea':
        fieldComp = (
          <Text
            name={name}
            field={field}
            disabled={disabled}
            confirmValue={formValues[field.confirms]}
            email={type === 'email'}
            textarea={type === 'textarea'}
          />
        );
        break;
      case 'toggle':
        fieldComp = <Toggle name={name} field={field} disabled={disabled} />;
        break;
      case 'toggleRadio':
        fieldComp = <ToggleR name={name} field={field} disabled={disabled} />;
        break;
      case 'checkbox':
        fieldComp = <Checkbox name={name} field={field} disabled={disabled} />;
        break;
      case 'radio':
        fieldComp = <Radio name={name} field={field} disabled={disabled} />;
        break;
      case 'date': {
        fieldComp = <Date name={name} field={field} disabled={disabled} />;
        break;
      }
      case 'time': {
        fieldComp = <Time name={name} field={field} disabled={disabled} />;
        break;
      }
      case 'autocomplete':
        fieldComp = (
          <AutoComplete
            name={name}
            field={field}
            disabled={disabled}
            value={formValues[name]}
            roles={roles}
            changeFieldValue={changeFieldValue}
          />
        );
        break;
      case 'twin':
        fieldComp = (
          <Twin
            name={name}
            field={field}
            disabled={disabled}
            value={formValues[name]}
            room={room}
            roles={roles}
            executeQuery={executeQuery}
            blockForm={blockForm}
            releaseForm={releaseForm}
          />
        );
        break;
      case 'select': {
        fieldComp = (
          <Select
            name={name}
            formName={formName}
            field={field}
            calendarMappings={calendarMappings}
            templateMappings={templateMappings}
            disabled={disabled}
            values={formValues[name]}
            isMobile={isMobile}
            isLoading={isLoading}
            roles={roles}
            eventList={eventList}
            setSelectedEvents={setSelectedEvents}
            initialValues={fieldInitialValue}
            quotaReached={quotaReached}
            changeFieldValue={changeFieldValue}
          />
        );
        break;
      }
      case 'file':
        fieldComp = (
          <FileUpload
            name={name}
            field={field}
            value={formValues[name]}
            releaseForm={releaseForm}
            uid={uid}
            assetsBaseUrl={assetsBaseUrl}
            filesBaseUrl={filesBaseUrl}
            uploadFile={uploadFile}
            blockForm={blockForm}
            registerField={registerField}
            changeFieldValue={changeFieldValue}
          />
        );
        break;
      case 'component': {
        const Comp = customComponents[customComp];
        fieldComp = Comp ? <Comp {...field} /> : null;
        break;
      }
      default:
        fieldComp = null;
        console.error('Unrecognized type', type);
    }

    return fieldComp;
  }

  renderCondition(fieldName, condition, cbComponent) {
    const { formName, formValues, roles, metrics } = this.props;
    const component = isConditionFulfilled(condition, formValues, {
      roles,
      metrics,
    })
      ? cbComponent()
      : null;
    return (
      <CSSTransitionGroup
        key={`form-${formName}-transition-group-${fieldName}`}
        transitionName="opacityTransition"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {component}
      </CSSTransitionGroup>
    );
  }

  renderFieldWrapper(field) {
    const { formName } = this.props;
    const { name, condition } = field;
    const cbField = () => (
      <div key={`form-${formName}-field-${name}`}>
        {this.renderField(field)}
      </div>
    );
    return !_.isNil(condition)
      ? this.renderCondition(name, condition, cbField)
      : cbField();
  }

  renderFields = fields => fields.map(this.renderFieldWrapper.bind(this));

  renderCard(card) {
    const { formName, isLoading, embeded } = this.props;
    const { formStructure, formValues, ...props } = this.props; // Get all props for stepper
    const { name, header, condition, fields, stepper } = card;
    const cbCard = () => {
      const hasHeader = !_.isNil(header) && _.isString(header);
      const wrapperElement = embeded ? 'div' : Card;
      const content = stepper ? (
        <Stepper {...stepper} renderFields={this.renderFields} {...props} />
      ) : (
        this.renderFields(fields)
      );
      const wrappedContent = embeded ? (
        content
      ) : (
        <CardContent>{content}</CardContent>
      );
      return React.createElement(
        // type
        wrapperElement,
        // props
        {
          key: `form-${formName}-card-${name}`,
          style: loaderStyles.wrapper,
        },
        // children...
        isLoading && Loader(),
        hasHeader && !embeded && <CardHeader title={header} />,
        wrappedContent,
      );
    };
    return !_.isNil(condition)
      ? this.renderCondition(name, condition, cbCard)
      : cbCard();
  }

  renderCards(cards) {
    return <div>{cards.map(this.renderCard.bind(this))}</div>;
  }

  renderStepper() {
    const { formStructure: stepper } = this.props;
    return this.renderCard({ name: 'stepper', stepper });
  }

  render() {
    // TODO Find out if there is a way to get rid of some rendering ...
    const {
      formStructure: { fields, cards, steps },
    } = this.props;
    if (steps) {
      return this.renderStepper();
    }
    if (cards) {
      return this.renderCards(cards);
    }
    if (fields) {
      return this.renderCard({ name: 'fields', fields });
    }
    return <div>Error reading the form structure</div>;
  }
}

export default SummonFields;
