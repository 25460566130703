import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CSSTransitionGroup } from 'react-transition-group';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import IconCloudDownload from '@material-ui/icons/CloudDownload';
import IconCheck from '@material-ui/icons/Check';

import * as Utils from '../../../../Utils';

const FileManagerClassic = props => {
  const {
    assetsBaseUrl,
    filesBaseUrl,
    name,
    files,
    filesProgress,
    canRemove,
    handleRemove,
  } = props;

  let component = null;
  if (!_.isEmpty(files)) {
    component = (
      <List>
        <ListSubheader>Added files</ListSubheader>
        <CSSTransitionGroup
          key={`transition-${name}-items`}
          transitionName="opacityTransition"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {files.map(({ meta }, index) => {
            const progress = filesProgress[meta.id];
            return (
              <div key={meta.id}>
                <ListItem>
                  <img
                    alt="File type"
                    src={`${assetsBaseUrl}/file-icons/32px/${meta.ext}.png`}
                  />
                  <ListItemText
                    primary={
                      <div style={{ position: 'relative' }}>
                        <CSSTransitionGroup
                          key={`transition-${name}-${meta.id}-progress`}
                          transitionName="opacityTransition"
                          transitionEnterTimeout={300}
                          transitionLeaveTimeout={300}
                        >
                          {!_.isUndefined(progress) && (
                            <div
                              key={`${meta.id}-progress`}
                              style={{
                                position: 'absolute',
                                height: '3px',
                                bottom: '-25px',
                                right: 0,
                                left: 0,
                              }}
                            >
                              <LinearProgress
                                variant={
                                  progress ? 'determinate' : 'indeterminate'
                                }
                                value={progress}
                                style={{ height: '100%' }}
                              />
                            </div>
                          )}
                        </CSSTransitionGroup>
                        <CSSTransitionGroup
                          key={`transition-${name}-${meta.id}-progress-done`}
                          transitionName="opacityTransition"
                          transitionEnterTimeout={300}
                          transitionLeaveTimeout={300}
                        >
                          {_.isUndefined(progress) && (
                            <div
                              key={`${meta.id}-progress`}
                              style={{
                                position: 'absolute',
                                height: '100%',
                                left: '-15px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <IconCheck
                                style={{ width: '12px', height: '12px' }}
                              />
                            </div>
                          )}
                        </CSSTransitionGroup>
                        <div
                          style={{
                            position: 'relative',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '13pt',
                            lineHeight: '12pt',
                          }}
                        >
                          {meta.name}
                        </div>
                      </div>
                    }
                    secondary={Utils.humanFileSize(meta.size)}
                  />
                  <ListItemSecondaryAction>
                    <div>
                      {_.isUndefined(progress) ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${filesBaseUrl}/${meta.id}`}
                        >
                          <IconButton>
                            <IconCloudDownload />
                          </IconButton>
                        </a>
                      ) : null}
                      {canRemove ? (
                        <IconButton onClick={() => handleRemove(meta.id)}>
                          <IconDelete />
                        </IconButton>
                      ) : null}
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < files.length - 1 && <Divider />}
              </div>
            );
          })}
        </CSSTransitionGroup>
      </List>
    );
  }

  return (
    <CSSTransitionGroup
      key={`transition-${name}`}
      transitionName="opacityTransition"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {component}
    </CSSTransitionGroup>
  );
};

FileManagerClassic.propTypes = {
  assetsBaseUrl: PropTypes.string.isRequired,
  filesBaseUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.shape,
    }),
  ).isRequired,
  filesProgress: PropTypes.shape(),
  canRemove: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func,
};

FileManagerClassic.defaultProps = {
  handleRemove: _.noop,
  filesProgress: {},
};

export default FileManagerClassic;
