import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import countries from 'i18n-iso-countries';

import SummonField from './SummonField';

import { requiredValidator } from '../validators';

const countryNames = _(countries.getNames('en'))
  .map((country, countryCode) => ({
    label: country,
    value: countryCode,
  }))
  .orderBy('label')
  .value();

class AutoComplete extends React.Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    field: PropTypes.shape().isRequired,
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // onChange: PropTypes.func,
    style: PropTypes.shape(),
    // changeFieldValue: PropTypes.func,
  };

  static defaultProps = {
    roles: [],
    // value: '',
    // onChange: _.noop,
    style: {},
    // changeFieldValue: _.noop,
  };

  constructor(props) {
    super(props);

    let data = this.isCountry() ? countryNames : props.field.options;
    // Remove unaccessible data because of lacking user role
    data = data.filter(
      d => d.force || _.isEmpty(d.role) || _.includes(props.roles, d.role),
    );

    this.state = { data };
  }

  // componentDidMount() {
  //   const { value } = this.props;
  //   this.autoComplete = this.ref.getRenderedComponent().getRenderedComponent();
  //   this.setValue(value);
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { value } = this.props;
  //   if (value !== nextProps.value && !_.isEmpty(nextProps.value)) {
  //     this.setValueIfEmpty(nextProps.value);
  //   }
  // }

  // onClose = () => {
  //   const { searchText } = this.autoComplete.state;
  //   this.setLabel(searchText);
  // };

  // setValue(value) {
  //   const { data } = this.state;
  //   const objectValue = _.find(data, item => item.value === value);
  //   this.setObjectValue(objectValue);
  // }

  // setValueIfEmpty(value) {
  //   if (_.isEmpty(this.autoComplete.state.searchText)) {
  //     this.setValue(value);
  //   }
  // }

  // setLabel(text) {
  //   const { data } = this.state;
  //   const objectValue = _.find(
  //     data,
  //     item => item.label.toLowerCase() === text.toLowerCase(),
  //   );
  //   this.setObjectValue(objectValue);
  // }

  // setObjectValue(objectValue) {
  //   const { changeFieldValue, onChange, name } = this.props;
  //   if (_.isEmpty(objectValue)) {
  //     this.autoComplete.setState(
  //       () => ({ searchText: '' }),
  //       () => {
  //         changeFieldValue(name, '');
  //         onChange(null);
  //       },
  //     );
  //   } else {
  //     this.autoComplete.setState(
  //       () => ({ searchText: objectValue.label }),
  //       () => {
  //         changeFieldValue(name, objectValue.value);
  //         onChange(objectValue.value);
  //       },
  //     );
  //   }
  // }

  isCountry() {
    const { field } = this.props;
    return field.country === true;
  }

  render() {
    const { name, disabled, style, field } = this.props;
    const {
      label,
      labelStyle = {},
      // openOnFocus = true, //
      // minChar = 1, //
      required,
      options,
      country,
      ...input
    } = field;

    const { data } = this.state;
    const validatorsArray = [];
    if (required) validatorsArray.push(requiredValidator);

    return (
      <div style={style}>
        <SummonField
          key={`summonField-${name}`}
          reduxFormField={{
            name,
            validate: [...validatorsArray],
            // ref: ref => {
            //   this.ref = ref;
            // },
            // withRef: true,
          }}
          component="autocomplete"
          componentProps={{
            /*
            list: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string }))
              .isRequired,
            placeholder: PropTypes.string,
            classes: PropTypes.object.isRequired,
            deleteOnEnter: PropTypes.bool,
            submitValue: PropTypes.func.isRequired,
            preventSubmit: PropTypes.bool,
            deleteValue: PropTypes.func,
            onClose: PropTypes.func,
            initialLabel: PropTypes.string,
            small: PropTypes.bool,
            focus: PropTypes.bool,
             */
            label: label && (
              <span style={labelStyle}>
                {label}
                {required && (
                  <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
                )}
              </span>
            ),
            disabled,
            fullWidth: true,
            list: data,
            onClose: this.onClose,
            ...input,
          }}
        />
      </div>
    );
  }
}

export default AutoComplete;
