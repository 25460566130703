import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { hasSubmitSucceeded } from 'redux-form';

import SummonForm from '../../lib/SummonForm/SummonForm';

import { handleSubmit } from '../../redux/root/actionCreators';

import forms from '../../config/forms.yaml';
import { application as applicationMessages } from '../../i18n/dynamic';

class Transfer extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    // React-Intl
    intl: intlShape.isRequired,
    // Redux
    dispatch: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape(),
    submitSucceeded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  componentWillReceiveProps(nextProps) {
    const { router } = this.context;
    if (nextProps.submitSucceeded) {
      window.scrollTo(0, 0);
      router.history.push('/registration');
    }
  }

  handleSubmit = rawValues => {
    const { dispatch, intl } = this.props;
    return dispatch(handleSubmit({ ...rawValues, sendMailing: true })).catch(
      error => {
        // FIXME - User friendly error handling
        if (error.code === 'CredentialsError') {
          // eslint-disable-next-line no-alert
          alert(`${intl.formatMessage(applicationMessages.sessionExpired)}`);
        } else {
          // eslint-disable-next-line no-alert
          alert(`${intl.formatMessage(applicationMessages.errorOccured)}`);
        }
        window.location.reload();
      },
    );
  };

  render() {
    const { isMobile, isLoading, initialValues } = this.props;
    return (
      <div className="tab">
        <SummonForm
          formName="inscriptionForm"
          formStructure={forms.transfer}
          onSubmit={this.handleSubmit}
          isMobile={isMobile}
          isLoading={isLoading}
          initialValues={!_.isEmpty(initialValues) ? initialValues : null}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ root, summonForm, form }) => ({
  isMobile: root.isMobile,
  isLoading: root.isLoading,
  initialValues: summonForm.values,
  submitSucceeded: hasSubmitSucceeded('inscriptionForm')({ form }),
});

export default _.flowRight(
  connect(mapStateToProps),
  injectIntl,
)(Transfer);
