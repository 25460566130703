import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../tools/config-css-loader.js!../../node_modules/yaml-loader/index.js?asJSON=true!./customization.yaml";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};