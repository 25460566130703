import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getFormValues } from 'redux-form';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { getMenuItems } from '../../selectors/menu';

const RegistrationOk = props => {
  const { firstName, lastName, menuItems, roles } = props;

  let mainFormTabIndex = _.findIndex(menuItems, item => item.mainForm === true);
  mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
  const mainFormTab = {
    ...menuItems[mainFormTabIndex],
    index: mainFormTabIndex,
  };

  const { eventList } = props;
  const selectedEvents = eventList.filter(e => e.selected);

  const style = {
    margin: 12,
    align: 'center',
    minWidth: 180,
  };
  const transferButton =
    roles.indexOf('transfer') === -1 ? null : (
      <Button
        variant="contained"
        color="primary"
        style={style}
        component={Link}
        to="/transfer"
      >
        Finalise your transfer
      </Button>
    );
  return (
    <div>
      <h1>
        <FormattedMessage
          id="registration.tankyou"
          defaultMessage="Thank you {firstName} {lastName}"
          values={{ firstName, lastName }}
        />
      </h1>
      <div>
        <h1>Registration confirmed</h1>
        <p>
          We are delighted to confirm your attendance to the Global Investor
          Seminar 2023.
        </p>
        {selectedEvents.length > 0 && (
          <div>
            <h2>Add your program to your calendar:</h2>
            <ul>
              <li>
                <a href="#/calendar/ical">Outlook (.ics)</a>
              </li>
            </ul>
          </div>
        )}
        <p>
          Should you have any questions or inquiries, please contact us via{' '}
          <a href="mailto:AltsEvents@axa-im.com">
          AltsEvents@axa-im.com
          </a>
        </p>
        <p>We are looking forward to welcoming you in Paris</p>
      </div>
      {transferButton}
      <Button
        variant="contained"
        color="primary"
        style={style}
        component={Link}
        to={`${mainFormTab.href}/modify`}
      >
        <FormattedMessage
          id="registration.button.modify"
          defaultMessage="Modify your registration"
        />
      </Button>
    </div>
  );
};

RegistrationOk.propTypes = {
  eventList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ root, profile, form }) => ({
  eventList: root.eventList,
  formValues: getFormValues('inscriptionForm')({ form }),
  firstName: profile.firstName,
  lastName: profile.lastName,
  roles: profile.roles,
  menuItems: getMenuItems({ profile }),
});

export default connect(mapStateToProps)(RegistrationOk);
